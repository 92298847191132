<template>
  <div
      class="roska-product-card"
      :class="{
        'square': square,
        'round': round,
      }"
  >
    <div
        class="image"
        :style="{ backgroundImage: `url('${ src }')` }"
    >
      <router-link
          :to="{ path: `/catalog/${ article }` }"
          class="link"
      ></router-link>

      <roska-like
          v-if="likeButton"
          class="like"
          :liked="liked"
          @like="$emit('like')"
      />

      <div
          v-else-if="unlikeButton"
          class="unlike"
          @click="$emit('like')"
      >
        <div class="line1"></div>

        <div class="line2"></div>
      </div>

      <div
          v-if="addToBagButton"
          class="addToBag"
      ></div>
    </div>

    <div class="description">
      <router-link
          :to="{ path: `/catalog/${ article }` }"
          v-slot="{ navigate }"
          custom
      >
        <div class="name" @click="navigate">
          <h5>{{ title.toUpperCase() }}</h5>
        </div>
      </router-link>

      <div class="cost">
        <h3>€{{ cost.toFixed(2) }}</h3>

        <h3 v-if="oldCost">€{{ oldCost.toFixed(2) }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import roskaLike from "@/components/UI-kit/roska-like";

export default {
  name: "roska-product-card",

  components: {
    roskaLike,
  },

  props: {
    article: {
      type: String,
      default: null,
    },

    src: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: "title",
    },

    cost: {
      type: Number,
      default: 0,
    },

    oldCost: {
      type: Number,
      default: null,
    },

    square: {
      type: Boolean,
      default: false,
    },

    round: {
      type: Boolean,
      default: false,
    },

    likeButton: {
      type: Boolean,
      default: false,
    },

    liked: {
      type: Boolean,
      default: false,
    },

    addToBagButton: {
      type: Boolean,
      default: false,
    },

    unlikeButton: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="sass">
.roska-product-card
  display: flex
  flex-direction: column
  gap: 20px

  @media (min-width: $desktopScreenMinWidth)
    width: 289px

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%

  .image
    +background-image-settings()
    padding-top: calc(400 / 289 * 100%)
    +border-radius(20px)
    position: relative
    overflow: hidden
    width: 100%
    display: flex

    .link
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0

    .like
      top: 0
      right: 0

    .unlike
      width: 32px
      height: 32px
      position: absolute
      top: 0
      right: 0
      cursor: pointer
      display: none
      align-items: center
      justify-content: center
      border: 15px solid transparent
      +box-sizing(content-box)
      +border-radius(0 20px 0 0)

      .line1
        width: 12px
        height: 2px
        +border-radius(999px)
        background-color: $closeButtonGray
        position: absolute
        transform: rotate(45deg)

      .line2
        width: 12px
        height: 2px
        +border-radius(999px)
        background-color: $closeButtonGray
        position: absolute
        transform: rotate(-45deg)

    .addToBag
      +background-image-settings()
      background-image: url("/src/assets/images/catalog/items/addToBag.svg")
      width: 32px
      height: 32px
      position: absolute
      bottom: 0
      right: 0
      cursor: pointer
      pointer-events: all
      border: 15px solid transparent
      +box-sizing(content-box)
      +border-radius(0 0 20px 0)

  .description
    display: flex
    flex-direction: column
    gap: 10px
    height: 100%
    flex: 1
    text-align: left

    .name
      > *
        font-size: 20px
        line-height: 110%
        min-height: calc(20px * 1.1 * 2)

    .cost
      display: flex
      column-gap: 10px
      margin-top: auto
      flex-wrap: wrap

      > *
        font-size: 20px
        line-height: 130%

        &:nth-child(2)
          text-decoration: line-through
          color: $text-lightgray

  &:hover
    .unlike
      display: flex

  &.square
    width: 100%

    .image
      width: 100%
      padding-top: 100%
      +background-image-settings(cover, no-repeat, top center)

  &.round
    width: 100%

    .image
      width: 100%
      padding-top: 100%
      +background-image-settings(cover, no-repeat, top center)
      +border-radius(50%)
</style>
