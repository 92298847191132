import { render, staticRenderFns } from "./roska-product-about.vue?vue&type=template&id=e1fa632a&scoped=true&"
import script from "./roska-product-about.vue?vue&type=script&lang=js&"
export * from "./roska-product-about.vue?vue&type=script&lang=js&"
import style0 from "./roska-product-about.vue?vue&type=style&index=0&id=e1fa632a&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1fa632a",
  null
  
)

export default component.exports