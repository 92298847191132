<template>
  <div class="roska-filter-menu-item" :class="isActive ? 'active' : ''">
    <header @click="isActive = !isActive">
      <h3 class="title">{{ title }}</h3>

      <div class="chevron"></div>
    </header>

    <main v-show="isActive">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "roska-filter-menu-item",

  props: {
    title: {
      type: String,
      default: "title",
    },
  },

  data() {
    return {
      isActive: true,
    }
  },
}
</script>

<style scoped lang="sass">
.roska-filter-menu-item
  padding: 15px 20px 20px
  display: flex
  flex-direction: column
  gap: 16px

  &.active
    .chevron
      transform: rotate(0)

    main
      display: block

  header
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer

    .title
      font-size: 20px
      line-height: 150%

    .chevron
      +background-image-settings()
      background-image: url("/src/assets/images/catalog/options/chevron-up.svg")
      width: 16px
      height: 16px
      transform: rotate(180deg)

  main
    display: none
</style>
