var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-signUp",on:{"click":_vm.closeModal}},[_c('div',{staticClass:"modal-window"},[_c('h1',{staticClass:"title"},[_vm._v("REGISTRATION")]),_c('div',{staticClass:"form"},[_c('main',[_c('div',{staticClass:"inputs"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail),expression:"mail"}],class:{
                  'error': _vm.mailError
                },attrs:{"type":"text","name":"email","id":"email","placeholder":"E-Mail"},domProps:{"value":(_vm.mail)},on:{"focus":function($event){_vm.mailError = false},"input":function($event){if($event.target.composing)return;_vm.mail=$event.target.value}}}),_c('input',{class:{
                  'error': _vm.passwordError
                },attrs:{"type":"password","name":"password","id":"password","placeholder":"Password"},on:{"focus":function($event){_vm.passwordError = false}}}),_c('input',{class:{
                  'error': _vm.error
                },attrs:{"type":"password","name":"password","id":"confirm_password","placeholder":"Confirm your password"},on:{"focus":function($event){_vm.error = false}}}),_c('div',{staticClass:"country-select",class:{
                  'error': _vm.countryError,
                },on:{"click":function($event){_vm.countryError = false}}},[_c('country-select',{attrs:{"country":_vm.country,"topCountry":"US","countryName":true},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)]),_c('h5',{staticClass:"forgot",on:{"click":_vm.openSignInModal}},[_vm._v("Already registered?")])]),_c('input',{staticClass:"submit",attrs:{"type":"submit","value":"Register"},on:{"click":_vm.signUp}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }