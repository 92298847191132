<template>
  <div
      v-if="product !== null"
      class="roska-main-woman"
  >
    <h1 class="title">W<span class="special-symbol-O">O</span>MEN</h1>

    <div class="product">
      <div class="left">
        <div
            class="back-image"
            :style="{ backgroundImage: secondImageUrl }"
            @click="redirectToProductPage"
        ></div>

        <router-link
            :to="{ path: '/catalog', query: { sex: 'women' }}"
            v-slot="{ navigate }"
            custom
        >
          <div class="link" @click="navigate">
            <div class="redline">
              <div class="button">
                <h4>VIEW<br>ALL</h4>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="right">
        <div
            class="round-image"
            :style="{ backgroundImage: thirdImageUrl }"
            @click="redirectToProductPage"
        ></div>

        <div class="main-product">
          <div
              class="image"
              :style="{ backgroundImage: firstImageUrl }"
              @click="redirectToProductPage"
          ></div>

          <div class="description">
            <h5
                @click="redirectToProductPage"
                class="name"
            >{{ product.title.toUpperCase() }}</h5>

            <h3 class="cost">€{{ product.price.toFixed() }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-main-woman",

  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  computed: {
    productPageUrl() {
      if (!this.product) {
        return ''
      }

      return `/catalog/${ this.product.id }`
    },

    firstImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image1 === null) {
        return ""
      }

      if (this.product.image1 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image1 }')`
    },

    secondImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image2 === null) {
        return ""
      }

      if (this.product.image2 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image2 }')`
    },

    thirdImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image3 === null) {
        return ""
      }

      if (this.product.image3 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image3 }')`
    },
  },

  methods: {
    redirectToProductPage() {
      this.$router.push({ path: this.productPageUrl })
    },
  },
}
</script>

<style scoped lang="sass">
.roska-main-woman
  display: flex
  flex-direction: column
  align-items: center
  background-color: $gray

  @media (min-width: $desktopScreenMinWidth)
    padding-top: 140px
    padding-bottom: 65px
    gap: 60px

  @media (max-width: $mobileScreenMaxWidth)
    padding-top: 50px
    padding-bottom: 25px
    gap: 30px

  .title
    @media (min-width: $desktopScreenMinWidth)
      font-size: 100px
      line-height: 120px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 48px
      line-height: 58px

    .special-symbol-O
      position: relative
      display: inline-flex
      justify-content: center

      @media (min-width: $desktopScreenMinWidth)
        width: 66px

      @media (max-width: $mobileScreenMaxWidth)
        width: 34px

      &::before
        content: ""
        display: inline-block
        position: absolute
        background-color: white
        +border-radius(50%)
        border: 1px solid black

        @media (min-width: $desktopScreenMinWidth)
          left: 0
          top: 11px
          width: 62px
          height: 80px

        @media (max-width: $mobileScreenMaxWidth)
          left: 1px
          top: 5px
          width: 30px
          height: 40px

      &::after
        position: absolute
        content: ""
        display: inline-block
        width: 35px
        height: 47px
        background-color: $crimson-red
        +border-radius(50%)

        @media (min-width: $desktopScreenMinWidth)
          width: 35px
          height: 47px
          top: calc(11px + (82px - 47px) / 2)
          left: calc((64px - 35px) / 2)

        @media (max-width: $mobileScreenMaxWidth)
          width: 17px
          height: 24px
          top: calc(5px + (42px - 24px) / 2)
          left: calc(1px + (32px - 17px) / 2)

  .product
    display: flex

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column-reverse
      width: 320px

    .left
      display: flex
      justify-content: flex-end
      z-index: 2

      @media (min-width: $desktopScreenMinWidth)
        position: relative
        right: -53px
        padding-top: 20px
        align-items: flex-start

      @media (max-width: $mobileScreenMaxWidth)

      .back-image
        @media (min-width: $desktopScreenMinWidth)
          background-image: url("/src/assets/images/main/woman/back.png")
          +background-image-settings()
          width: 369px
          height: 495px
          +border-radius(20px)
          transform: rotate(-4deg)
          cursor: pointer

        @media (max-width: $mobileScreenMaxWidth)
          display: none

      .link
        +border-radius(50%)
        +user-select(none)
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          position: relative
          margin-top: calc(692px - 20px - 160px - 10px)

        @media (max-width: $mobileScreenMaxWidth)

        .redline
          +border-radius(50%)

          @media (min-width: $desktopScreenMinWidth)
            border: 3px solid $crimson-red
            padding: 3px
            width: 160px
            height: 160px

          @media (max-width: $mobileScreenMaxWidth)
            border: 2px solid $crimson-red
            padding: 2px
            width: 108px
            height: 108px

          .button
            width: 100%
            height: 100%
            +border-radius(50%)
            display: flex
            align-items: center
            justify-content: center
            text-align: center

            @media (min-width: $desktopScreenMinWidth)
              font-size: 24px
              line-height: 29px
              border: 2px solid white

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 16px
              line-height: 19px
              border: 1px solid black

    .right
      display: flex

      @media (min-width: $desktopScreenMinWidth)
        position: relative
        left: -50px

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column
        align-items: center
        gap: 20px

      .round-image
        background-image: url("/src/assets/images/main/woman/mini.png")
        +background-image-settings()
        +border-radius(50%)
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          width: 309px
          height: 309px
          border: 10px solid $gray
          margin-top: calc(692px - 309px)
          right: -103px
          position: relative

        @media (max-width: $mobileScreenMaxWidth)
          display: none

      .main-product
        display: flex
        flex-direction: column
        gap: 20px

        .image
          background-image: url("/src/assets/images/main/woman/main.png")
          +background-image-settings()
          +border-radius(20px)
          cursor: pointer

          @media (min-width: $desktopScreenMinWidth)
            width: 495px
            height: 692px

          @media (max-width: $mobileScreenMaxWidth)
            width: 320px
            height: 400px

        .description
          display: flex
          flex-direction: column
          gap: 10px

          .name
            font-size: 20px
            line-height: 110%
            cursor: pointer

          .cost
            font-size: 20px
            line-height: 130%
</style>
