import Vue from 'vue'
import App from './App.vue'

import '/src/assets/styles/style.css'
import 'swiper/css';

import VueRouter from "vue-router";
import router from "./router"

import axios from "axios";
import VueAxios from 'vue-axios';

import vueCountryRegionSelect from 'vue-country-region-select'

Vue.config.productionTip = false

Vue
  .use(VueAxios, axios)
  .use(VueRouter)
  .use(vueCountryRegionSelect)

new Vue({
  router,
  render: h => h(App),
})
  .$mount('#app')
