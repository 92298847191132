<template>
  <div class="roska-breadcrumbs">
    <template
        v-for="crumb in breadCrumbs"
    >
      <router-link
          :to="crumb.link"
          :key="`roska-breadcrumbs-link-${crumb.title}`"
      >
        <h5>{{ crumb.title }}</h5>
      </router-link>

      <h5
          class="slash"
          :key="`roska-breadcrumbs-text-${crumb.title}`"
      >\</h5>
    </template>

    <h5>{{ currentCrumb.title }}</h5>
  </div>
</template>

<script>
export default {
  name: "roska-breadcrumbs",

  props: {
    crumbs: {
      type: Array,
      default: () => {
        return [{
          title: "HOME",
        }]
      },
    },
  },

  computed: {
    breadCrumbs() {
      return [{
        title: "HOME",
        link: {
          path: `/`
        }
      }, ...this.crumbs.slice(0, -1)]
    },

    currentCrumb() {
      return this.crumbs.at(-1)
    }
  }
}
</script>

<style scoped lang="sass">
.roska-breadcrumbs
  padding-bottom: 40px
  display: flex
  gap: 10px
  color: $text-lightgray
  padding-top: 20px

  @media (min-width: $desktopScreenMinWidth)
    width: 1216px

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%
    padding-left: 20px
    padding-right: 20px

  > *:last-child
    color: black
    cursor: default

  *
    font-size: 15px
    line-height: 130%

    &.slash
      cursor: default
</style>
