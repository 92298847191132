<template>
  <div class="roska-catalog-options">
    <div class="sex">
      <h5
          v-for="sexOption in [null, 'man', 'women']"
          :key="sexOption"
          @click="linkSexOption(sexOption)"
          :class="{ 'active': sex === sexOption }"
      >
        {{ sexOption === null ? "ALL" : sexOption.toUpperCase() }}
      </h5>
    </div>

    <div
        v-if="false"
        class="filters"
        @click="handleFilterMenu"
        :class="{ active: !isHiddenFilterMenu }"
    >
      <div class="only-ds">
        <h3>FILTERS</h3>

        <div class="arrow"></div>
      </div>

      <roska-catalog-filter-menu
          v-if="!isHiddenFilterMenu"
          id="filterMenu"
          @close="isHiddenFilterMenu = !isHiddenFilterMenu"
          @updatePrice="(min, max) => $emit('updatePrice', min, max)"
      />
    </div>

    <div
        v-if="false"
        class="sorting"
    >
      <div class="only-ds">
        <h5>POPULAR</h5>

        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import roskaCatalogFilterMenu from "@/components/templates/catalog/options/filterMenu/roska-catalog-filter-menu";

export default {
  name: "roska-catalog-options",

  components: {
    roskaCatalogFilterMenu,
  },

  props: {
    sex: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isHiddenFilterMenu: true,
    }
  },

  methods: {
    handleFilterMenu() {
      if (this.isHiddenFilterMenu) {
        this.isHiddenFilterMenu = !this.isHiddenFilterMenu
      }
    },

    linkSexOption(sex) {
      if (sex === null) {
        this.$router.push({ path: '/catalog' })
      } else {
        this.$router.push({ path: '/catalog', query: { sex: sex } })
      }
    },
  },
}
</script>

<style scoped lang="sass">
.roska-catalog-options
  display: flex

  @media (min-width: $desktopScreenMinWidth)
    gap: 30px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 21px
    width: 100%

  .sex
    display: flex
    gap: 10px
    +user-select(none)

    > *
      +box-sizing(border-box)
      +border-radius(999px)
      border: 1px solid $lightgray
      color: $text-lightgray
      cursor: pointer
      line-height: 150%

      @media (min-width: $desktopScreenMinWidth)
        padding: 8px 20px
        font-size: 17px

      @media (max-width: $mobileScreenMaxWidth)
        padding: 4px 12px
        font-size: 15px

      &.active
        background-color: $crimson_red
        border: none
        color: white

        @media (min-width: $desktopScreenMinWidth)
          padding: 9px 21px

        @media (max-width: $mobileScreenMaxWidth)
          padding: 5px 13px

  .filters
    cursor: pointer
    position: relative
    +user-select(none)

    @media (min-width: $desktopScreenMinWidth)

    @media (max-width: $mobileScreenMaxWidth)
      +background-image-settings()
      background-image: url("/src/assets/images/catalog/options/filters.svg")
      width: 32px
      height: 32px
      margin-left: auto

    &.active
      cursor: default

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        background-image: none

    .only-ds
      @media (min-width: $desktopScreenMinWidth)
        display: flex
        align-items: center
        gap: 10px
        height: 100%

        *
          font-size: 20px
          line-height: 150%

        .arrow
          +background-image-settings()
          background-image: url("/src/assets/images/catalog/options/arrow-bottom.svg")
          width: 16px
          height: 16px

      @media (max-width: $mobileScreenMaxWidth)
        display: none

  .sorting
    cursor: pointer
    position: relative

    @media (min-width: $desktopScreenMinWidth)
      margin-left: auto

    @media (max-width: $mobileScreenMaxWidth)
      +background-image-settings()
      background-image: url("/src/assets/images/catalog/options/sorting.svg")
      width: 32px
      height: 32px
      cursor: pointer

    .only-ds
      @media (min-width: $desktopScreenMinWidth)
        display: flex
        align-items: center
        gap: 10px
        color: $text-lightgray
        height: 100%

        *
          font-size: 15px
          line-height: 150%

        .arrow
          +background-image-settings()
          background-image: url("/src/assets/images/catalog/options/arrow-bottom-gray.svg")
          width: 16px
          height: 16px

      @media (max-width: $mobileScreenMaxWidth)
        display: none
</style>
