<template>
  <div class="roska-card">
    <template v-if="!pageLoading">
      <template v-if="currentProduct">
        <roska-breadcrumbs :crumbs="crumbs" />

        <main>
          <h1 class="name">{{ currentProduct.title }}</h1>

          <roska-card-images
              :images="productImages"
              :liked="wishlist.includes(currentProduct.id)"
              @like="$emit('like', currentProduct.id)"
              @addToBag="addToBag"
          />

          <div class="info">
            <div class="main">
              <div class="name-cost-volume">
                <h1 class="name">{{ currentProduct.title }}</h1>

                <div class="cost">
                  <h3>€{{ currentProduct.price }}</h3>

                  <h3 v-if="currentProduct.old_price">€{{ currentProduct.old_price }}</h3>
                </div>

                <h5
                    v-if="this.isNewProduct"
                    class="soon"
                >WILL BE AVAILABLE SOON</h5>

                <roska-volume
                    v-if="!this.isNewProduct"
                    :minValue="0"
                    :volume="currentVolume"
                    :maxValue="maxCount"
                    :disabled="currentSize === null"
                    :warning="needToSelectVolume"
                    @update="newValue => {this.currentVolume = newValue; this.needToSelectVolume = false}"
                    @disabledClick="needToSelectSizeWarning = true"
                />
              </div>

              <div
                  v-if="!this.isNewProduct"
                  class="color"
              >
                <h3 class="title">COLOR</h3>

                <roska-product-select-color
                    :colors="colors"
                    :selected-color="currentColorIndex"
                    @changeColor="newColorIndex => this.currentColorIndex = newColorIndex"
                />
              </div>

              <div
                  v-if="!this.isNewProduct"
                  class="size"
              >
                <h3 class="title">SIZE <span v-if="this.isColorSoldOut">SOLD OUT</span></h3>

                <roska-product-select-size
                    :sizes="sizes"
                    :selected-size="currentSize"
                    :warning="needToSelectSizeWarning"
                    @changeSize="changeSize"
                />
              </div>
            </div>

            <roska-product-about
                :product="currentProduct"
            />
          </div>
        </main>

        <roska-customers-also-liked class="roska-card-customers-also-liked" :productsList="alsoProductsList" />
      </template>

      <div v-else class="not-found">
        <h1 class="title">PRODUCT WITH ARTICLE <i>{{ currentPageArticle }}</i> NOT FOUND</h1>
      </div>
    </template>
  </div>
</template>

<script>
import roskaBreadcrumbs from "@/components/UI-kit/roska-breadcrumbs";
import roskaCardImages from "@/components/templates/product-card/images/roska-card-images";
import roskaVolume from "@/components/UI-kit/volume/roska-volume";
import roskaProductSelectColor from "@/components/templates/product-card/roska-product-select-color";
import roskaProductSelectSize from "@/components/templates/product-card/roska-product-select-size";
import roskaProductAbout from "@/components/templates/product-card/roska-product-about";
import roskaCustomersAlsoLiked from "@/components/UI-kit/roska-customers-also-liked";

export default {
  name: "roska-card",

  components: {
    roskaBreadcrumbs,
    roskaCardImages,
    roskaVolume,
    roskaProductSelectColor,
    roskaProductSelectSize,
    roskaProductAbout,
    roskaCustomersAlsoLiked
  },

  props: {
    wishlist: {
      type: Array,
      default: () => {
        return []
      }
    },

    productsList: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      pageLoading: true,

      productAvailability: [],

      needToSelectSizeWarning: false,
      needToSelectVolume: false,

      currentVolume: 0,
      currentColorIndex: 0,
      currentSize: null,
    }
  },

  computed: {
    currentProduct() {
      return this.productsList.find(product => product.id === this.currentPageArticle)
    },

    currentPageArticle() {
      return this.$route.params.article
    },

    productImages() {
      const array = []

      if (this.currentProduct.image1) {
        array.push(`https://media.roskainc.com/media/${this.currentProduct.image1}`)
      }

      if (this.currentProduct.image2) {
        array.push(`https://media.roskainc.com/media/${this.currentProduct.image2}`)
      }

      if (this.currentProduct.image3) {
        array.push(`https://media.roskainc.com/media/${this.currentProduct.image3}`)
      }

      return array
    },

    crumbs() {
      return [
        {
          title: "CATALOG",
          link: { path: `/catalog` },
        },

        {
          title: this.currentProduct.sex.toUpperCase(),
          link: { path: `/catalog`, query: { sex: this.currentProduct.sex } },
        },

        {
          title: this.currentProduct.title,
        },
      ]
    },

    colors() {
      const colors = {}

      this.productAvailability.forEach(el => colors[el.color] = true)

      return Object.keys(colors)
    },

    sizes() {
      const sizes = {}

      this.productAvailability.filter(el => el.color === this.colors[this.currentColorIndex]).forEach(el => sizes[el.size] = el.count > 0)

      return sizes
    },

    maxCount() {
      const currentAvailability = this.productAvailability.find(el => el.color === this.colors[this.currentColorIndex] && el.size === this.currentSize)

      if (currentAvailability === undefined) {
        return 0
      }

      return Number(currentAvailability.count)
    },

    alsoProductsList() {
      return this.productsList.filter(product => product.id !== this.currentPageArticle)
    },

    isNewProduct() {
      return this.colors.length === 0
    },

    isColorSoldOut() {
      return Object.keys(this.sizes).every(key => !this.sizes[key])
    },
  },

  methods: {
    changeSize(size) {
      this.currentSize = size
      this.needToSelectSizeWarning = false
    },

    addToBag() {
      if (this.currentSize === null) {
        this.needToSelectSizeWarning = true
        return
      }

      if (!this.currentVolume > 0) {
        this.needToSelectVolume = true
        return
      }

      this.$emit('addToBag',
          this.currentPageArticle,
          this.currentProduct.stripe_id,
          this.colors[this.currentColorIndex],
          this.currentSize,
          this.currentVolume,
          this.maxCount)
    },

    setPageTitle() {
      document.title = `${ document.title } - ${ this.currentProduct.title.toUpperCase() }`
    },

    async getProductAvailability() {
      this.pageLoading = true
      this.productAvailability = []

      await this.axios.get("https://api.roskainc.com/api/store/v1/products/readAvailability", {
        params: {
          'product_uuid': this.currentProduct.id,
        },
      })
          .then(response => {
            this.productAvailability = response.data

            console.log('PRODUCT DATA', response.data)
          })
          .catch(error => {
            console.log("Getting Product Availability Error", error)
          })

      this.pageLoading = false
    }
  },

  watch: {
    currentPageArticle: {
      immediate: true,
      async handler() {
        this.setPageTitle()
        await this.getProductAvailability()
      }
    },

    colors() {
      this.currentColorIndex = 0
    },

    sizes() {
      this.currentSize = this.sizes[0] || null
    }
  },
}
</script>

<style scoped lang="sass">
.roska-card
  display: flex
  flex-direction: column
  align-items: center
  width: 100%

  main
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      width: 1216px
      gap: 123px

    @media (max-width: $mobileScreenMaxWidth)
      width: 100%
      flex-direction: column
      gap: 30px

    > .name
      @media (min-width: $desktopScreenMinWidth)
        display: none

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 30px
        line-height: 36px
        padding-left: 20px
        padding-right: 20px
        text-align: center

    .info
      flex: 1
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 80px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 40px
        padding-left: 20px
        padding-right: 20px

      .main
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 30px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 20px

        .name-cost-volume
          display: flex
          flex-direction: column
          align-items: flex-start
          gap: 20px

          .soon
            font-size: 20px
            line-height: 110%

          .name
            @media (min-width: $desktopScreenMinWidth)
              font-size: 60px
              line-height: 110%

            @media (max-width: $mobileScreenMaxWidth)
              display: none

          .cost
            display: flex
            gap: 20px
            align-items: center

            > *:first-child
              font-size: 30px
              line-height: 130%

            > *:nth-child(2)
              font-size: 24px
              line-height: 130%
              text-decoration: line-through
              color: $text-lightgray

        .color
          display: flex
          flex-direction: column

          @media (min-width: $desktopScreenMinWidth)
            gap: 12px

          @media (max-width: $mobileScreenMaxWidth)
            gap: 10px

          > .title
            font-size: 20px
            line-height: 150%

        .size
          display: flex
          flex-direction: column
          align-items: flex-start

          @media (min-width: $desktopScreenMinWidth)
            gap: 16px

          @media (max-width: $mobileScreenMaxWidth)
            gap: 10px

          > .title
            font-size: 20px
            line-height: 150%

            > *
              color: $crimson_red
              font-size: 16px
              line-height: 150%

  .not-found
    padding: 150px 20px

    .title
      font-size: 60px
      line-height: 150%
      text-align: center

  &-customers-also-liked
    margin-top: auto
</style>
