<template>
  <div class="roska-footer">
    <section>
      <router-link to="/" v-slot="{ navigate }" custom>
        <roska-logo @click="navigate"/>
      </router-link>

      <nav>
        <router-link
            :to="{ path: '/catalog', query: { sex: 'man' }}"
            v-slot="{ navigate }"
            custom
        >
          <h5 @click="navigate">MAN</h5>
        </router-link>

        <router-link
            :to="{ path: '/catalog', query: { sex: 'women' }}"
            v-slot="{ navigate }"
            custom
        >
          <h5 @click="navigate">WOMEN</h5>
        </router-link>

        <router-link
            :to="{ path: '/sale', query: { sex: 'women' }}"
            v-slot="{ navigate }"
            custom
        >
          <h5 @click="navigate">SALE</h5>
        </router-link>
      </nav>
    </section>

    <div class="contacts">
      <h3><a href="mailto:info@roskainc.com">INFO@ROSKAINC.COM</a></h3>

<!--      @TODO Ссылка на Instagram -->
      <img src="../../../assets/images/social_icons/instagram_icon.svg" alt="Instagram icon">
    </div>
  </div>
</template>

<script>
import roskaLogo from "@/components/UI-kit/roska-logo";

export default {
  name: "roska-footer",

  components: {
    roskaLogo
  },
}
</script>

<style scoped lang="sass">
.roska-footer
  background-color: $crimson-red
  display: flex
  justify-content: center
  color: $text-white
  margin-top: auto
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    gap: 245px
    padding: 80px

  @media (max-width: $mobileScreenMaxWidth)
    flex-direction: column
    align-items: center
    gap: 30px
    padding: 50px

  section
    display: flex
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 107px

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      gap: 40px

    nav
      display: flex

      @media (min-width: $desktopScreenMinWidth)
        gap: 40px
        align-items: baseline

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column
        align-items: center
        gap: 20px

      > *
        font-size: 20px
        line-height: 24px
        cursor: pointer

  .contacts
    display: flex
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 80px

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      gap: 30px

    > *:first-child
      @media (min-width: $desktopScreenMinWidth)
        font-size: 24px
        line-height: 29px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 20px
        line-height: 24px
</style>
