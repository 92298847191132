<template>
  <div class="roska-card-images">
    <roska-card-images-mobile :images="images" class="only-mb" />

    <roska-card-images-desktop :images="images" class="only-ds" />

    <roska-round-button
        class="button"
        :title="'ADD TO BAG'"
        @click="$emit('addToBag')"
    />

    <roska-like
        class="like"
        :white-round="true"
        :liked="liked"
        @like="$emit('like')"
    />
  </div>
</template>

<script>
import roskaCardImagesMobile from "@/components/templates/product-card/images/roska-card-images-mobile";
import roskaCardImagesDesktop from "@/components/templates/product-card/images/roska-card-images-desktop";

import roskaRoundButton from "@/components/UI-kit/roska-round-button";
import roskaLike from "@/components/UI-kit/roska-like";

export default {
  name: "roska-card-images",

  components: {
    roskaCardImagesMobile,
    roskaCardImagesDesktop,
    roskaRoundButton,
    roskaLike,
  },

  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    },

    liked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="sass">
.roska-card-images
  position: relative

  .button
    @media (min-width: $desktopScreenMinWidth)
      position: absolute
      top: 92px
      right: -80px

    @media (max-width: $mobileScreenMaxWidth)
      position: fixed
      bottom: 6px
      right: 20px

  .like
    top: 0

    @media (min-width: $desktopScreenMinWidth)
      right: 0

    @media (max-width: $mobileScreenMaxWidth)
      left: 20px
</style>
