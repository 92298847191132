<template>
  <div class="search">
    <roska-coming-soon title="SEARCH" />
  </div>
</template>

<script>
import RoskaComingSoon from "@/components/templates/coming-soon/roska-coming-soon";

export default {
  name: "roska-search",

  components: {
    RoskaComingSoon,
  },
}
</script>

<style scoped lang="sass">
.search
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
</style>