<template>
  <div
      v-if="product !== null"
      class="roska-main-man"
  >
    <h1 class="title">M<span class="special-symbol-A">A</span>N</h1>

    <div class="product">
      <div class="left">
        <div
            class="main-image"
            :style="{ backgroundImage: firstImageUrl }"
            @click="redirectToProductPage"
        ></div>

        <div
            class="back-image"
            :style="{ backgroundImage: secondImageUrl }"
            @click="redirectToProductPage"
        ></div>
      </div>

      <div class="right">
        <div class="product-card">
          <div
              class="product-image"
              :style="{ backgroundImage: thirdImageUrl }"
              @click="redirectToProductPage"
          ></div>

          <div class="product-description">
            <h5
                class="name"
                @click="redirectToProductPage"
            >{{ product.title.toUpperCase() }}</h5>

            <h3 class="cost">€{{ product.price.toFixed(2) }}</h3>
          </div>
        </div>

        <router-link
            :to="{ path: '/catalog', query: { sex: 'man' }}"
            v-slot="{ navigate }"
            custom
        >
          <div class="link" @click="navigate">
            <div class="round">
              <div class="redline">
                <div class="button">
                  <h4>VIEW<br>ALL</h4>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-main-man",

  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  computed: {
    productPageUrl() {
      if (!this.product) {
        return ''
      }

      return `/catalog/${ this.product.id }`
    },

    firstImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image1 === null) {
        return ""
      }

      if (this.product.image1 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image1 }')`
    },

    secondImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image2 === null) {
        return ""
      }

      if (this.product.image2 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image2 }')`
    },

    thirdImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image3 === null) {
        return ""
      }

      if (this.product.image3 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image3 }')`
    },
  },

  methods: {
    redirectToProductPage() {
      this.$router.push({ path: this.productPageUrl })
    },
  },
}
</script>

<style scoped lang="sass">
.roska-main-man
  display: flex
  flex-direction: column
  align-items: center
  background-color: white
  gap: 30px

  @media (min-width: $desktopScreenMinWidth)
    padding-top: 130px
    padding-bottom: 150px

  @media (max-width: $mobileScreenMaxWidth)
    padding-top: 50px
    padding-bottom: 50px

  .title
    @media (min-width: $desktopScreenMinWidth)
      font-size: 100px
      line-height: 120px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 48px
      line-height: 58px

    .special-symbol-A
      display: inline-flex
      -webkit-text-stroke: 1px black
      color: white
      position: relative
      z-index: 3

      &::before
        @media (min-width: $desktopScreenMinWidth)
          top: 30px
          left: 12px
          position: absolute
          display: inline-block
          width: 15px
          height: 37px
          background-color: $crimson-red
          content: ""
          z-index: -2

        @media (max-width: $mobileScreenMaxWidth)
          top: 13px
          left: 6px
          position: absolute
          display: inline-block
          width: 7px
          height: 20px
          background-color: $crimson-red
          content: ""
          z-index: -2

  .product
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 43px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 20px
      flex-direction: column

    .left
      display: flex
      gap: 20px
      padding-top: 30px
      align-items: flex-end

      .main-image
        +background-image-settings()
        background-image: url("/src/assets/images/main/man/main.png")
        +border-radius(20px)
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          width: 495px
          height: 692px

        @media (max-width: $mobileScreenMaxWidth)
          width: 320px
          height: 400px

      .back-image
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          +background-image-settings()
          background-image: url("/src/assets/images/main/man/back.png")
          width: 369px
          height: 495px
          +border-radius(20px)

        @media (max-width: $mobileScreenMaxWidth)
          display: none

    .right
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        position: relative

      @media (max-width: $mobileScreenMaxWidth)
        align-items: flex-end

      .product-card
        display: flex
        flex-direction: column
        gap: 20px

        @media (min-width: $desktopScreenMinWidth)
          width: 289px

        @media (max-width: $mobileScreenMaxWidth)
          width: 320px

        .product-image
          background-image: url("/src/assets/images/main/man/mini.png")
          +background-image-settings()
          +border-radius(50%)
          cursor: pointer

          @media (min-width: $desktopScreenMinWidth)
            width: 289px
            height: 289px

          @media (max-width: $mobileScreenMaxWidth)
            display: none

        .product-description
          display: flex
          flex-direction: column
          gap: 10px

          .name
            font-size: 20px
            line-height: 110%
            cursor: pointer

          .cost
            font-size: 20px
            line-height: 130%

      .link
        display: flex
        +user-select(none)
        +border-radius(50%)
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          left: calc(0px - 43px - 40px)
          position: absolute
          bottom: 0

        @media (max-width: $mobileScreenMaxWidth)
          margin-right: 15px

        .round
          background-color: #ffffff
          padding: 10px
          +border-radius(50%)

          .redline
            +border-radius(50%)
            background-color: white

            @media (min-width: $desktopScreenMinWidth)
              border: 3px solid $crimson-red
              padding: 3px
              width: 160px
              height: 160px

            @media (max-width: $mobileScreenMaxWidth)
              border: 2px solid $crimson-red
              padding: 2px
              width: 108px
              height: 108px

            .button
              width: 100%
              height: 100%
              +border-radius(50%)
              display: flex
              align-items: center
              justify-content: center
              text-align: center
              border: 1px solid black

              @media (min-width: $desktopScreenMinWidth)
                font-size: 24px
                line-height: 29px

              @media (max-width: $mobileScreenMaxWidth)
                font-size: 16px
                line-height: 19px
</style>
