<template>
  <div class="roska-color-round"
    :style="{
      background: `conic-gradient(${ getColorsStyle(colorsArray) })`
    }"
  />
</template>

<script>
export default {
  name: "roska-color-round",

  props: {
    colorsArray: {
      type: String,
      default: 'black',
    },
  },

  methods: {
    getColorsStyle(color) {
      if (Array.isArray(color)) {
        let stringArray = []

        color.forEach((el, index) => {
          let string = ""
          string += el + " "
          string += index * 100 / color.length + "% "
          string += (index + 1) * 100 / color.length + "%"
          stringArray.push(string)
        })

        return stringArray.join(', ')
      } else {
        return `${color} 0% 100%`
      }
    }
  },
}
</script>

<style scoped lang="sass">
.roska-color-round
  +border-radius(50%)
  width: 24px
  height: 24px
</style>
