var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-header"},[_c('roska-header-menu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isBurgerMenuHidden),expression:"!isBurgerMenuHidden"}],attrs:{"checkBeforeLinkClick":!_vm.isUserLoggedIn},on:{"closeButtonClick":function($event){_vm.isBurgerMenuHidden = !_vm.isBurgerMenuHidden},"signInClick":_vm.handleAccountClick}}),_c('div',{class:{
        'gray': true,
        'back': true,
        'only-ds': true
      }}),_c('div',{staticClass:"shadow back only-mb"}),_c('div',{staticClass:"black back only-mb",style:({
        opacity: Math.min(_vm.scrollY / 100, 100)
      })}),_c('nav',{staticClass:"only-ds"},[_c('router-link',{attrs:{"to":{ path: '/catalog', query: { sex: 'man' }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('h5',{on:{"click":navigate}},[_vm._v("MAN")])]}}])}),_c('router-link',{attrs:{"to":{ path: '/catalog', query: { sex: 'women' }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('h5',{on:{"click":navigate}},[_vm._v("WOMEN")])]}}])}),_c('router-link',{attrs:{"to":{ path: '/collection' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('div',{staticClass:"with-arrow",on:{"click":navigate}},[_c('h5',[_vm._v("COLLECTION")]),_c('div',{staticClass:"arrow"})])]}}])}),_c('router-link',{attrs:{"to":{ path: '/sale' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('h5',{on:{"click":navigate}},[_vm._v("SALE")])]}}])})],1),_c('div',{staticClass:"main-page only-mb"},[_c('div',{staticClass:"burger only-mb",on:{"click":function($event){_vm.isBurgerMenuHidden = !_vm.isBurgerMenuHidden}}},[_c('div',{staticClass:"line-black",style:({
            backgroundColor: 'white'
          })}),_c('div',{staticClass:"line-red"}),_c('div',{staticClass:"line-black",style:({
            backgroundColor: 'white'
          })})])]),_c('roska-logo',{staticClass:"logo"}),_c('div',{staticClass:"tools"},[_c('roska-header-icon',{staticClass:"only-ds",attrs:{"src":"/assets/images/header/find.svg","link":"/search"}}),_c('roska-header-icon',{staticClass:"only-ds",attrs:{"src":"/assets/images/header/hearth.svg","link":"/wishlist","count":_vm.wishlistCount}}),_c('roska-header-icon',{staticClass:"only-ds",attrs:{"src":"/assets/images/header/basket.svg","link":"/basket","count":_vm.bagCount}}),_c('roska-header-icon',{staticClass:"only-mb",attrs:{"src":"/assets/images/header/basket-white.svg","link":"/basket","count":_vm.bagCount}}),_c('roska-header-icon',{staticClass:"only-ds",attrs:{"src":"/assets/images/header/profile.svg","link":"/account","checkBeforeLinkClick":!_vm.isUserLoggedIn},on:{"click":_vm.handleAccountClick}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }