<template>
  <div class="roska-account-orders-order">
    <h3 class="title">ORDER #{{ orderId }}</h3>

    <div class="main">
      <h4>€{{ value }}</h4>

      <h4>{{ sumOfItems }}</h4>

      <h4>{{ orderDate }}</h4>

      <h4>STATUS:
        <span
            :class="{
              orange: orderStatus === 'Waiting for pay'
            }"
        >{{ orderStatus }}</span>
      </h4>
    </div>

    <h3 class="title">SHIPPING</h3>

    <div class="shipping">
      <h4>First Name: {{ firstNameOrder }}</h4>
      <h4>Country: {{ countryOrder }}</h4>
      <h4>State: {{ stateOrder }}</h4>
      <h4>City: {{ cityOrder }}</h4>
      <h4>Street: {{ streetOrder }}</h4>
      <h4>Zip Code: {{ zipCodeOrder }}</h4>
    </div>

    <h3 class="title">PRODUCTS</h3>

    <div
        class="product"
        v-for="product in products"
        :key="`roska-account-orders-order-${ product.id }`"
    >
      <div
          class="image"
          :style="{
            backgroundImage: `url(https://media.roskainc.com/media/${ productsList.find(item => item.id === product.product_id).image1 })`
          }"
          @click="redirectToProductPage(product.product_id)"
      ></div>

      <div class="description">
        <h3
            class="title"
            @click="redirectToProductPage(product.product_id)"
        >{{ productsList.find(item => item.id === product.product_id).title }}</h3>

        <div class="other">
          <h4>COLOR: {{ product.color }}</h4>
          <h4>SIZE: {{ product.size }}</h4>
          <h4>COUNT: {{ product.quantity }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-account-orders-order",

  props: {
    userId: {
      type: String,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      ordersList: [],

      productsList: [],
    }
  },

  computed: {
    orderId() {
      return this.$route.params.orderId
    },

    orderObj() {
      if (!this.ordersList) {
        return {}
      }

      return this.ordersList.find(order => order.id === this.orderId)
    },

    value() {
      if (!this.orderObj) {
        return 0
      }

      return this.orderObj.value
    },

    sumOfItems() {
      if (!this.orderObj) {
        return 0
      }

      const sum = this.orderObj.products.reduce((a, b) => a + b.quantity, 0)
      return `${ sum.toString() } ITEM ${ sum > 1 ? 'S' : '' }`
    },

    orderDate() {
      if (!this.orderObj) {
        return ''
      }

      return new Date(this.orderObj.created)
    },

    orderStatus() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.status
    },

    firstNameOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.first_name
    },

    countryOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.country
    },

    stateOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.state
    },

    cityOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.city
    },

    streetOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.street
    },

    zipCodeOrder() {
      if (!this.orderObj) {
        return ''
      }

      return this.orderObj.zip_code
    },

    products() {
      if (!this.orderObj) {
        return []
      }

      return this.orderObj.products
    },
  },

  methods: {
    redirectToProductPage(productId) {
      this.$router.push({ path: "/catalog/" + productId })
    },

    async getUserOrders() {
      await this.axios.get("https://api.roskainc.com/api/store/v1/users/orders", {
        params: {
          user_id: this.userId,
        },
      })
          .then(response => {
            console.log('[AXIOS GET] Orders List', response.data)

            this.ordersList = response.data
          })
          .catch(error => {
            console.log("[AXIOS GET ERROR] Orders List", error)
          })
    },

    async getProductData(productId) {
      await this.axios.get("https://api.roskainc.com/api/store/v1/products/read", {
        params: {
          product_uuid: productId,
        },
      })
          .then(response => {
            console.log(`[AXIOS GET] Product Data (${ productId })`, response.data)

            this.productsList.push(response.data)
          })
          .catch(error => {
            console.log(`[AXIOS GET ERROR] Product Data (${ productId })`, error)
          })
    },
  },

  watch: {
    userId: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.ordersList.length > 0) {
          return
        }

        if (!val) {
          return
        }

        this.getUserOrders();
      },
    },

    products: {
      immediate: true,
      deep: true,
      handler(val) {
        val.forEach(async product => {
          if (this.productsList.find(item => item.id === product.product_id)) {
            return
          }

          await this.getProductData(product.product_id)
        })
      },
    },
  },
}
</script>

<style scoped lang="sass">
.roska-account-orders-order
  display: flex
  flex-direction: column
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    gap: 30px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 12px

  .title
    font-size: 30px

  .main
    display: flex
    flex-direction: column
    gap: 14px

    .orange
      color: darkorange

  .shipping
    display: flex
    flex-direction: column
    gap: 10px

  .product
    display: flex
    justify-content: space-between
    gap: 50px
    align-items: center

    .image
      width: 200px
      height: 200px
      +border-radius(20px)
      +background-image-settings()
      cursor: pointer

    .description
      width: 100%
      flex: 1
      display: flex
      flex-direction: column
      justify-content: space-between
      align-self: stretch
      padding: 20px

      .title
        font-size: 24px
        cursor: pointer

      .other
        display: flex
        flex-direction: column
        gap: 12px
</style>