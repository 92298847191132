<template>
  <div class="test-page">
    <swiper
        class="swiper"
        :slides-per-view="1"
        @touchStart="start"
        @touchEnd="end"
    >
      <swiper-slide class="swiper-slide">
        Slide 1
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        Slide 2
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2'

export default {
  name: "roska-test-page",

  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    start() {
      console.log('start')
      document.querySelector('.swiper').classList.toggle('active')
    },

    end() {
      console.log('end')
      document.querySelector('.swiper').classList.toggle('active')
    },
  },
}
</script>

<style scoped lang="sass">
.test-page
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  background: repeating-linear-gradient(45deg, #ce7474, #ce7474 10px, #cb8989 10px, #cb8989 20px)
  margin: 20px

  .swiper
    background-color: #E7D7B7
    width: 400px
    height: 200px
    cursor: grab

    &.active
      cursor: grabbing

    .swiper-slide
      background-color: aquamarine
      font-family: "Calibri Light", sans-serif
      font-weight: 700
      font-size: 60px
      color: black
      display: flex
      align-items: center
      justify-content: center
</style>