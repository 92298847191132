import { render, staticRenderFns } from "./roska-volume.vue?vue&type=template&id=62f099a5&scoped=true&"
import script from "./roska-volume.vue?vue&type=script&lang=js&"
export * from "./roska-volume.vue?vue&type=script&lang=js&"
import style0 from "./roska-volume.vue?vue&type=style&index=0&id=62f099a5&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f099a5",
  null
  
)

export default component.exports