<template>
  <div
      class="roska-round-button"
      :class="{
          'mobile': mobile
      }"
      @click="$emit('click')"
  >
    <div class="round">
      <h4>{{ title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-round-button",

  props: {
    title: {
      type: String,
      default: "Button",
    },

    mobile: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="sass">
.roska-round-button
  background-color: $crimson_red
  +border-radius(50%)
  cursor: pointer
  z-index: 999
  width: 160px
  height: 160px
  padding: 6px

  .round
    width: 100%
    height: 100%
    border: 2px solid white
    +border-radius(50%)
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    color: white
    font-size: 24px
    line-height: 29px

  &.mobile
    @media (min-width: $desktopScreenMinWidth)
      width: 160px
      height: 160px
      padding: 6px

    @media (max-width: $mobileScreenMaxWidth)
      width: 108px
      height: 108px
      padding: 4px

    .round
      @media (min-width: $desktopScreenMinWidth)
        font-size: 24px
        line-height: 29px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 16px
        line-height: 19px
</style>
