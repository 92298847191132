<template>
  <div class="roska-wishlist">
    <roska-breadcrumbs :crumbs="crumbs" />

    <main>
      <h1 class="title">{{ title }}</h1>

      <div class="wrapper">
        <roska-product-card
            v-for="product in currentPageProductsList"
            :key="`roska-wishlist-${product.id}`"

            :add-to-bag-button="true"
            :unlike-button="true"

            :article="product.id"
            :src="`https://media.roskainc.com/media/${product.image1}`"
            :title="product.title"
            :cost="product.price"
            :old-cost="product.old_price > 0 ? product.old_price : null"

            @like="$emit('like', product.id)"
        />
      </div>

      <roska-pagination
          :currentPage="currentPage"
          :productsVolume="currentPageProductsList.length"
          :maxProductsOnPage="maxProductsOnPage"
          @changePage="pageNumber => this.currentPage = pageNumber"
      />
    </main>

    <roska-customers-also-liked :productsList="alsoProductsList" />
  </div>
</template>

<script>
import roskaBreadcrumbs from "@/components/UI-kit/roska-breadcrumbs";
import roskaProductCard from "@/components/UI-kit/roska-product-card";
import roskaPagination from "@/components/UI-kit/roska-pagination";
import roskaCustomersAlsoLiked from "@/components/UI-kit/roska-customers-also-liked";

export default {
  name: "roska-wishlist",

  components: {
    roskaBreadcrumbs,
    roskaProductCard,
    roskaPagination,
    roskaCustomersAlsoLiked,
  },

  props: {
    wishlist: {
      type: Array,
      default: () => {
        return []
      },
    },

    productsList: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      maxProductsOnPage: 12,
      currentPage: 1,
    }
  },

  computed: {
    currentPageProductsList() {
      return this.filteredProductsList.slice(this.maxProductsOnPage * (this.currentPage - 1), this.maxProductsOnPage + this.maxProductsOnPage * (this.currentPage - 1))
    },

    filteredProductsList() {
      return this.productsList.filter(product => this.wishlist.includes(product.id))
    },

    crumbs() {
      return [{
          title: "WISHLIST",
        }]
    },

    title() {
      const itemsVolume = this.wishlist.length
      if (itemsVolume) {
        if (itemsVolume > 1) {
          return `YOU HAVE ${ this.wishlist.length } ITEMS IN WISHLIST`
        } else {
          return `YOU HAVE ${ this.wishlist.length } ITEM IN WISHLIST`
        }
      } else {
        return `YOUR WISHLIST IS EMPTY`
      }
    },

    alsoProductsList() {
      return this.productsList.filter(product => !this.wishlist.includes(product.id))
    },
  },
}
</script>

<style scoped lang="sass">
.roska-wishlist
  display: flex
  flex-direction: column
  align-items: center
  width: 100%

  main
    display: flex
    flex-direction: column
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 60px
      width: 1216px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 40px
      width: 100%
      padding-left: 20px
      padding-right: 20px

    .title
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 60px
        line-height: 72px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 30px
        line-height: 36px

    .wrapper
      //display: grid
      //grid-template-columns: repeat(auto-fill, minmax(max(289px, 100%/5), 1fr))
      column-gap: 20px
      width: 100%

      @media (min-width: $desktopScreenMinWidth)
        row-gap: 30px
        display: flex
        flex-wrap: wrap
        justify-content: flex-start

      @media (max-width: $mobileScreenMaxWidth)
        row-gap: 20px
        display: grid
        grid-template-columns: repeat(2, 1fr)
</style>
