<template>
  <div class="roska-account-profile">
    <section>
      <h3 class="title">CONTACT INFORMATION</h3>

      <div class="wrapper">
        <input type="text" placeholder="FIRST NAME" :value="userData['first_name']" name="first_name">

        <input type="text" placeholder="LAST NAME" :value="userData['last_name']" name="last_name">

        <input type="text" placeholder="E-MAIL" :value="userData['email']" name="email">
      </div>

      <div class="wrapper">
        <input type="text" placeholder="DATE BIRTHDAY" :value="userData['birth_date']" name="birth_date">

        <input type="text" placeholder="SEX" :value="userData['gender']" name="gender">
      </div>
    </section>

    <section>
      <h3 class="title">SHIPPING ADDRESS</h3>

      <div class="wrapper">
        <div class="input">
          <country-select v-model="country" :country="country" topCountry="US" :countryName="true" />
        </div>

        <div class="input">
          <region-select v-model="state" :country="country" :region="state" :countryName="true" :regionName="true" />
        </div>

        <input type="text" placeholder="CITY" :value="userData['city']" name="city">

        <input type="text" placeholder="STREET" :value="userData['street']" name="street">

        <input type="text" placeholder="ZIP CODE" :value="userData['zip_code']" name="zip_code">
      </div>
    </section>

    <button
        :class="{
          'save': true,
          'loading': loading,
        }"
        @click="handleSaveButton"
    >SAVE</button>

    <button class="sign-out" @click="$emit('signOut')">SIGN OUT</button>

  </div>
</template>

<script>
export default {
  name: "roska-account-profile",

  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    }
  },

  data() {
    return {
      loading: false,
      error: false,

      country: null,
      state: null,
    }
  },

  methods: {
    handleSaveButton() {
      this.loading = true

      const first_name = this.$el.querySelector('[name="first_name"]').value
      const last_name = this.$el.querySelector('[name="last_name"]').value
      const email = this.$el.querySelector('[name="email"]').value
      const birth_date = this.$el.querySelector('[name="birth_date"]').value
      const gender = this.$el.querySelector('[name="gender"]').value
      const country = this.country
      const state = this.state
      const city = this.$el.querySelector('[name="city"]').value
      const street = this.$el.querySelector('[name="street"]').value
      const zip_code = this.$el.querySelector('[name="zip_code"]').value

      const isChanged = first_name !== this.userData['first_name'] ||
          last_name !== this.userData['last_name'] ||
          email !== this.userData['email'] ||
          birth_date !== this.userData['birth_date'] ||
          gender !== this.userData['gender'] ||
          country !== this.userData['country'] ||
          state !== this.userData['state'] ||
          city !== this.userData['city'] ||
          street !== this.userData['street'] ||
          zip_code !== this.userData['zip_code']

      if (!isChanged) {
        this.loading = false
        return
      }

      const newUserData = {
          'first_name': first_name,
          'last_name': last_name,
          'surname': "",
          'country': country,
          'email': email,
          'consent_to_mailing': false,
          'birth_date': '2000-01-01',
          'gender': gender,
          'state': state,
          'city': city,
          'street': street,
          'zip_code': zip_code,
      }

      this.$emit('updateUserData', newUserData)

      this.loading = false
    },
  },

  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) {
          return
        }

        this.country = val.country
        this.state = val.state
      }
    },
  },
}
</script>

<style scoped lang="sass">
@keyframes rotate
  100%
    transform: rotate(360deg)

.roska-account-profile
  display: flex
  flex-direction: column
  gap: 40px
  //align-items: flex-start

  @media (min-width: $desktopScreenMinWidth)

  @media (max-width: $mobileScreenMaxWidth)
    padding: 0 20px

  section
    display: flex
    flex-direction: column
    gap: 20px

    .title
      line-height: 150%

      @media (min-width: $desktopScreenMinWidth)
        font-size: 20px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 22px
        text-align: center

    .wrapper
      gap: 20px

      @media (min-width: $desktopScreenMinWidth)
        display: flex
        flex-wrap: wrap

      @media (max-width: $mobileScreenMaxWidth)
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(max(250px), 1fr))

      input
        padding: 10px 20px
        +border-radius(999px)
        border: 1px solid $red
        font-family: Inter, "Calibri Light", sans-serif
        font-size: 17px
        line-height: 150%
        height: 26px
        box-sizing: content-box

        @media (min-width: $desktopScreenMinWidth)

        @media (max-width: $mobileScreenMaxWidth)
          text-align: center

        &::placeholder
          color: $text-lightgray

        &[disabled]
          cursor: not-allowed

      .input
        +border-radius(999px)
        border: 1px solid $red
        font-family: Inter, "Calibri Light", sans-serif
        font-size: 17px
        line-height: 150%
        overflow: hidden
        cursor: pointer

        @media (min-width: $desktopScreenMinWidth)
          width: 273px

        @media (max-width: $mobileScreenMaxWidth)
          *
            text-align: center

        *
          cursor: pointer

        > *
          padding: 10px 40px
          margin-right: -20px
          margin-left: -20px
          width: 100%
          box-sizing: content-box
          height: 26px

  .sign-out
    background-color: $crimson_red
    +border-radius(999px)
    padding: 10px 30px
    font-family: Inter, "Calibri Light", sans-serif
    font-size: 17px
    line-height: 150%
    font-weight: 400
    color: white
    cursor: pointer
    width: 150px

    @media (min-width: $desktopScreenMinWidth)
      align-self: flex-start

    @media (max-width: $mobileScreenMaxWidth)
      align-self: center

  .save
    background-color: $crimson_red
    +border-radius(999px)
    padding: 10px 30px
    font-family: Inter, "Calibri Light", sans-serif
    font-size: 17px
    line-height: 150%
    font-weight: 400
    color: white
    cursor: pointer
    width: 150px

    @media (min-width: $desktopScreenMinWidth)
      align-self: flex-start

    @media (max-width: $mobileScreenMaxWidth)
      align-self: center

    &.loading
      background-color: #f58e96
      position: relative
      display: flex
      align-items: center
      justify-content: center

      &::after
        content: ""
        display: block
        width: 30px
        height: 30px
        position: absolute
        +border-radius(50%)
        background-image: url("/src/assets/images/svg/loading.svg")
        +background-image-settings()
        animation: rotate 2s linear infinite
</style>
