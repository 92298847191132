<template>
  <div class="roska-header-menu">
    <div class="close-button" @click="$emit('closeButtonClick')">
      <div class="line1"></div>

      <div class="line2"></div>
    </div>

    <div class="menu">
      <nav>
        <router-link
            :to="{ path: '/catalog', query: { sex: 'man' }}"
        >
          <h5 @click="$emit('closeButtonClick')">MAN</h5>
        </router-link>

        <router-link
            :to="{ path: '/catalog', query: { sex: 'women' }}"
        >
          <h5 @click="$emit('closeButtonClick')">WOMEN</h5>
        </router-link>

        <router-link
            :to="{ path: '/collection' }"
        >
          <h5 @click="$emit('closeButtonClick')">COLLECTION</h5>
        </router-link>

        <router-link
            :to="{ path: '/sale' }"
        >
          <h5 @click="$emit('closeButtonClick')">SALE</h5>
        </router-link>
      </nav>

      <hr>

      <nav>
        <router-link
            v-if="!checkBeforeLinkClick"
            :to="{ path: '/account' }"
        >
          <h5 @click="$emit('closeButtonClick')">PROFILE</h5>
        </router-link>

        <h5 v-else @click="signInClick" style="cursor: pointer">SIGN IN</h5>

        <router-link
            :to="{ path: '/search' }"
        >
          <h5 @click="$emit('closeButtonClick')">SEARCH</h5>
        </router-link>

        <router-link
            :to="{ path: '/wishlist' }"
        >
          <h5 @click="$emit('closeButtonClick')">WISHLIST</h5>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-header-menu",

  props: {
    checkBeforeLinkClick: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    signInClick() {
      this.$emit('signInClick')
    },
  }
}
</script>

<style scoped lang="sass">
.roska-header-menu
  background-color: $dark-black
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 9999
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 40px
  padding: 30px 20px

  .close-button
    margin: 4px 0
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: 32px
    height: 32px
    cursor: pointer

    .line1
      background-color: white
      width: 24px
      height: 2px
      +border-radius(1px)
      transform: rotate(45deg)
      position: absolute

    .line2
      background-color: white
      width: 24px
      height: 2px
      +border-radius(1px)
      transform: rotate(-45deg)
      position: absolute

  .menu
    color: white
    gap: 20px
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%

    nav
      display: flex
      flex-direction: column
      font-size: 20px
      line-height: 24px
      cursor: pointer

      &:first-of-type
        gap: 16px

      &:last-of-type
        gap: 20px

    hr
      background-color: white
      height: 1px
      width: 100%
</style>
