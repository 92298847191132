<template>
  <div
      class="roska-volume"
      :class="{
          'roska-volume': true,
          'warning': warning,
        }"
  >
    <div
        class="minus"
        :class="{
          'active': volume > minValue
        }"
        @click="minusClick"
    >
      <div class="line"></div>
    </div>

    <h4 class="text">{{ volume }}</h4>

    <div
        class="plus"
        :class="{
          'active': volume < maxValue
        }"
        @click="plusClick"
    >
      <div class="line1"></div>
      <div class="line2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-volume",

  props: {
    minValue: {
      type: Number,
      default: 1,
    },

    volume: {
      type: Number,
      default: 1,
    },

    maxValue: {
      type: Number,
      default: 999,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    warning: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    minusClick() {
      if (this.disabled) {
        return this.$emit('disabledClick')
      }

      if (this.volume > this.minValue) {
        this.$emit('update', this.volume - 1)
      }
    },

    plusClick() {
      if (this.disabled) {
        return this.$emit('disabledClick')
      }

      if (this.volume < this.maxValue) {
        this.$emit('update', this.volume + 1)
      }
    },
  },

  watch: {
    minValue(newVal) {
      if (this.volume < newVal) {
        this.$emit('update', newVal)
      }
    },

    maxValue(newVal) {
      if (this.volume > newVal) {
        this.$emit('update', newVal)
      }
    },

    volume: {
      immediate: true,
      handler(newVal) {
        if (newVal > this.maxValue || newVal < this.minValue) {
          this.$emit('update', Math.max(this.minValue, Math.min(this.maxValue, newVal)))
        }
      },
    },
  },
}
</script>

<style scoped lang="sass">
*
  touch-action: manipulation

.roska-volume
  display: flex
  padding: 8px 20px
  gap: 10px
  +border-radius(999px)
  border: 1px solid $paginationGray
  align-items: center
  +user-select(none)

  &.warning
    +border-radius(999px)
    background-color: rgba(255, 0, 0, 0.2)

  .minus
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

    .line
      width: 12px
      height: 2px
      +border-radius(999px)
      background-color: $text-gray

    &.active
      .line
        background-color: black

  .text
    font-size: 24px
    line-height: 29px
    min-width: 32px
    text-align: center

  .plus
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    cursor: pointer

    .line1
      width: 12px
      height: 2px
      +border-radius(999px)
      background-color: $text-gray
      position: absolute

    .line2
      width: 2px
      height: 12px
      +border-radius(999px)
      background-color: $text-gray
      position: absolute

    &.active
      .line1
        background-color: black

      .line2
        background-color: black
</style>
