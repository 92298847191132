<template>
  <div class="roska-product-select-color">
    <div
        v-for="(color, index) in colors"
        :key="index"
        class="round-wrapper"
        :class="{
          'active': index === selectedColor
        }"
        @click="() => { if (index !== selectedColor) $emit('changeColor', index)}"
    >
      <div
          class="round"
          :style="{
          background: `conic-gradient(${ getColorsStyle(color) })`
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-product-select-color",

  props: {
    selectedColor: {
      type: Number,
      default: 0,
    },

    colors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  methods: {
    getColorsStyle(color) {
      if (Array.isArray(color)) {
        let stringArray = []

        color.forEach((el, index) => {
          let string = ""
          string += el + " "
          string += index * 100 / color.length + "% "
          string += (index + 1) * 100 / color.length + "%"
          stringArray.push(string)
        })

        return stringArray.join(', ')
      } else {
        return `${color} 0% 100%`
      }
    },
  },
}
</script>

<style scoped lang="sass">
.roska-product-select-color
  display: flex
  gap: 12px
  align-items: center

  .round-wrapper
    cursor: pointer

    &.active
      border: 2px solid black
      padding: 2px
      +border-radius(999px)
      margin: -4px

    .round
      +border-radius(50%)
      width: 24px
      height: 24px
</style>
