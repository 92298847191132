var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-volume",class:{
        'roska-volume': true,
        'warning': _vm.warning,
      }},[_c('div',{staticClass:"minus",class:{
        'active': _vm.volume > _vm.minValue
      },on:{"click":_vm.minusClick}},[_c('div',{staticClass:"line"})]),_c('h4',{staticClass:"text"},[_vm._v(_vm._s(_vm.volume))]),_c('div',{staticClass:"plus",class:{
        'active': _vm.volume < _vm.maxValue
      },on:{"click":_vm.plusClick}},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"line2"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }