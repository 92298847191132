<template>
  <img src="../../assets/images/logo/logo.svg" alt="" class="logo" @click="handlerLogoClick">
</template>

<script>
export default {
  name: "roska-logo",

  data() {
    return {
      homeRouteName: "main",
    }
  },

  methods: {
    handlerLogoClick() {
      if (this.$route.name === this.homeRouteName) {
        return
      }

      this.$router.push({ name: this.homeRouteName })
    },
  }
}
</script>

<style scoped lang="sass">
.logo
  cursor: pointer
  
  @media (min-width: $desktopScreenMinWidth)
    width: 203px

  @media (max-width: $mobileScreenMaxWidth)
    width: 103px
</style>
