var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-signIn",on:{"click":_vm.closeModal}},[_c('div',{staticClass:"modal-window"},[_c('h1',{staticClass:"title"},[_vm._v("LOGIN")]),_c('div',{staticClass:"form"},[_c('main',[_c('div',{staticClass:"inputs"},[_c('input',{class:{
                  'error': _vm.error
                },attrs:{"type":"text","name":"email","id":"email","placeholder":"E-Mail"},on:{"focus":function($event){_vm.error = false}}}),_c('input',{class:{
                  'error': _vm.error
                },attrs:{"type":"password","name":"password","id":"password","placeholder":"Password"},on:{"focus":function($event){_vm.error = false}}})]),_c('h5',{staticClass:"forgot",on:{"click":_vm.openSignUpModal}},[_vm._v("Not registered?")]),_c('div',{staticClass:"remember",on:{"click":function($event){_vm.rememberMe = !_vm.rememberMe}}},[_c('div',{staticClass:"checkbox",class:{
                  'active': _vm.rememberMe
                }}),_c('h5',{staticClass:"title"},[_vm._v("Remember me")])])]),_c('input',{staticClass:"submit",attrs:{"type":"submit","value":"Login"},on:{"click":_vm.signIn}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }