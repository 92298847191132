<template>
  <div class="coming-soon">
    <h1 class="title">{{ title ? `${title} IS` : '' }} COMING SOON</h1>
  </div>
</template>

<script>
export default {
  name: "roska-coming-soon",

  props: {
    title: {
      type: String,
      default() {
        return ""
      }
    },
  },
}
</script>

<style scoped lang="sass">
.coming-soon
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%

  .title
    font-size: 60px
    line-height: 150%
    text-align: center
</style>