<template>
  <div class="roska-catalog">
    <roska-breadcrumbs :crumbs="crumbs" />

    <div class="main">
      <h1 class="title">CATALOG</h1>

      <div class="catalog">
        <roska-catalog-options
            :sex="sex"
            @updatePrice="updatePrice"
        />

        <div class="wrapper">
          <roska-product-card
              v-for="product in currentPageProductsList"
              :key="product.id"

              :like-button="true"
              :liked="wishlist.includes(product.id)"
              :add-to-bag-button="true"

              :article="product.id"
              :src="`https://media.roskainc.com/media/${product.image1}`"
              :title="product.title"
              :cost="product.price"
              :old-cost="product.old_price > 0 ? product.old_price : null"

              @like="$emit('like', product.id)"
          />
        </div>

        <roska-pagination
            :currentPage="currentPage"
            :productsVolume="currentProductsList.length"
            :maxProductsOnPage="maxProductsOnPage"
            @changePage="pageNumber => this.currentPage = pageNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import roskaBreadcrumbs from "@/components/UI-kit/roska-breadcrumbs";
import roskaCatalogOptions from "@/components/templates/catalog/options/roska-catalog-options";
import roskaProductCard from "@/components/UI-kit/roska-product-card";
import roskaPagination from "@/components/UI-kit/roska-pagination";

export default {
  name: "roska-catalog",

  components: {
    roskaBreadcrumbs,
    roskaCatalogOptions,
    roskaProductCard,
    roskaPagination,
  },

  props: {
    wishlist: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      productsList: [],
      availability: {},

      minPrice: null,
      maxPrice: null,

      maxProductsOnPage: 12,
      currentPage: 1,
    }
  },

  computed: {
    currentPageProductsList() {
      return this.currentProductsList.slice(this.maxProductsOnPage * (this.currentPage - 1), this.maxProductsOnPage + this.maxProductsOnPage * (this.currentPage - 1))
    },

    currentProductsList() {
      return this.filterProductsList
    },

    filterProductsList() {
      // return this.sortProductsList.filter(product => {
      //   if (this.minPrice !== null) {
      //     if (product.cost < this.minPrice) {
      //       return false
      //     }
      //   }
      //
      //   if (this.maxPrice !== null) {
      //     if (product.cost > this.maxPrice) {
      //       return false
      //     }
      //   }
      //
      //   return true
      // })

      return this.sortProductsList
    },

    sortProductsList() {
      return this.sex ?
          this.systemFilterProductsList.filter(item => item.sex === this.sex) :
          this.systemFilterProductsList
    },

    systemFilterProductsList() {
      return this.productsList.filter(item => {
        if (item.description === "MAIN_PAGE_HEADER") {
          return false
        }

        if (item.description === "MAIN_PAGE_MAN") {
          return false
        }

        if (item.description === "MAIN_PAGE_WOMEN") {
          return false
        }

        return true
      })
    },

    sex() {
      return this.$route.query.sex || null
    },

    crumbs() {
      if (this.sex) {
        return [
          {
            title: "CATALOG",
            link: { path: "/catalog"},
          },

          {
            title: this.sex.toUpperCase()
          }
        ]
      } else {
        return [{
          title: "CATALOG"
        }]
      }
    },
  },

  methods: {
    updatePrice(min, max) {
      this.minPrice = min
      this.maxPrice = max
    },

    async getAllProducts() {
      this.axios.get("https://api.roskainc.com/api/store/v1/products/readAll")
          .then(response => {
            this.productsList = response.data
          })
          .catch(error => {
            console.log("Getting All Products Error:", error)
          })
    },
  },

  mounted() {
    this.getAllProducts()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  },
}
</script>

<style scoped lang="sass">
.roska-catalog
  display: flex
  flex-direction: column

  @media (min-width: $desktopScreenMinWidth)
    padding-bottom: 130px

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%
    padding-left: 20px
    padding-right: 20px
    padding-bottom: 60px

  .main
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 60px
      max-width: 1216px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px

    .title
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 100px
        line-height: 120px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 60px
        line-height: 72px

    .catalog
      display: flex
      flex-direction: column
      gap: 30px

      .wrapper
        //display: grid
        //grid-template-columns: repeat(auto-fill, minmax(max(289px, 100%/5), 1fr))
        column-gap: 20px

        @media (min-width: $desktopScreenMinWidth)
          row-gap: 30px
          display: flex
          flex-wrap: wrap
          justify-content: flex-start

        @media (max-width: $mobileScreenMaxWidth)
          row-gap: 20px
          display: grid
          grid-template-columns: repeat(2, 1fr)
</style>
