<template>
  <div class="roska-customers-also-liked">
    <h1 class="title">CUSTOMERS ALSO LIKED</h1>

    <main>
      <div class="button">
        <div class="arrow-left" @click="swiperEl.swiper.slidePrev()"></div>
      </div>

      <swiper
          class="swiper roskaCustomersAlsoLikedSwiper"
          :slidesPerView="slidersVolume"
          :breakpoints="{
            1: {
              spaceBetween: 10,
            },
            1920: {
              spaceBetween: 20,
            },
          }"
          @touchStart="grabbing"
          @touchEnd="grabbing"
      >
        <swiper-slide
            class="slide roskaCustomersAlsoLikedSlide"
            v-for="(product, index) in productsList"
            :key="`roska-customers-also-liked-${product.id}`"
        >
          <roska-product-card
              :square="true"
              :round="index % 4 === 1"

              :article="product.id"
              :src="`https://media.roskainc.com/media/${product.image1}`"
              :title="product.title"
              :cost="product.price"
              :old-cost="product.old_price ? product.old_price : null"
          />
        </swiper-slide>
      </swiper>

      <div class="button">
        <div class="arrow-right" @click="swiperEl.swiper.slideNext()"></div>
      </div>
    </main>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2';

import roskaProductCard from "@/components/UI-kit/roska-product-card";

export default {
  name: "roska-customers-also-liked",

  components: {
    Swiper,
    SwiperSlide,
    roskaProductCard,
  },

  props: {
    productsList: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      swapperWidth: 0,
      swapperSlideWidth: 0,
    }
  },

  computed: {
    slidersVolume() {
      if (this.swapperWidth >= 289 * 6 + 20 * 5) {
        return 6 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 5 + 20 * 4) {
        return 5 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 4 + 20 * 3) {
        return 4 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 3 + 20 * 2) {
        return 3 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 2 + 20) {
        return 2 + 31 / this.swapperSlideWidth
      }

      if (this.swapperSlideWidth) {
        return 1 + 31 / this.swapperSlideWidth
      }

      return 4
    },

    swiperEl() {
      return document.querySelector('.roskaCustomersAlsoLikedSwiper')
    }
  },

  methods: {
    getSwapperWidth() {
      this.swapperWidth = document.querySelector('.roskaCustomersAlsoLikedSwiper')?.clientWidth
      this.swapperSlideWidth = document.querySelector('.roskaCustomersAlsoLikedSlide')?.clientWidth
    },

    grabbing(e) {
      e.$el[0].classList.toggle('active')
    },
  },

  mounted() {
    this.getSwapperWidth()

    window.addEventListener('resize', this.getSwapperWidth)

    document.onload = this.getSwapperWidth
    window.onload = this.getSwapperWidth

    setTimeout(this.getSwapperWidth, 100)
    setTimeout(this.getSwapperWidth, 1000)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getSwapperWidth)
  },

  watch: {
    async productsList() {
      await this.getSwapperWidth()

      this.swiperEl.swiper.updateSize()
    },
  },
}
</script>

<style scoped lang="sass">

.roska-customers-also-liked
  display: flex
  flex-direction: column

  @media (min-width: $desktopScreenMinWidth)
    padding-top: 130px
    padding-bottom: 130px
    gap: 60px
    width: 1336px

  @media (max-width: $mobileScreenMaxWidth)
    padding-top: 60px
    padding-bottom: 60px
    gap: 30px
    width: 100%

  .title
    text-align: center

    @media (min-width: $desktopScreenMinWidth)
      font-size: 60px
      line-height: 110%

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 30px
      line-height: 36px

  main
    display: flex
    gap: 20px
    width: 100%

    .button
      @media (min-width: $desktopScreenMinWidth)
        height: 289px
        display: flex
        align-items: center

        .arrow-left
          width: 40px
          height: 40px
          +background-image-settings()
          background-image: url("/src/assets/images/svg/chevron-left.svg")
          cursor: pointer

        .arrow-right
          width: 40px
          height: 40px
          +background-image-settings()
          background-image: url("/src/assets/images/svg/chevron-left.svg")
          transform: rotate(180deg)
          cursor: pointer

      @media (max-width: $mobileScreenMaxWidth)
        display: none

    .swiper
      overflow: hidden
      cursor: grab !important

      @media (min-width: $desktopScreenMinWidth)
        width: 1216px

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%
        padding-right: 20px !important
        padding-left: 20px !important

      &.active
        cursor: grabbing !important
</style>
