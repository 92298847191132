<template>
  <swiper
      class="swiper roskaCardImagesMobileSwiper"
      :spaceBetween="20"
      :slidesPerView="getSlidersVolume"
      @touchStart="grabbing"
      @touchEnd="grabbing"
  >
    <swiper-slide
        v-for="(image, index) in images"
        :key="`roska_product-card_images_mobile_${index}`"
        class="slide roskaCardImagesMobileSlide"
    >
      <div
          class="image"
          :style="{ backgroundImage: `url('${ image }')` }"
      ></div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2';

export default {
  name: "roska-card-images-mobile",

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      swapperWidth: 0,
      swapperSlideWidth: 0,
    }
  },

  computed: {
    getSlidersVolume() {
      if (this.swapperWidth >= 289 * 6 + 20 * 5) {
        return 6 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 5 + 20 * 4) {
        return 5 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 4 + 20 * 3) {
        return 4 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 3 + 20 * 2) {
        return 3 + 31 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 2 + 20) {
        return 2 + 31 / this.swapperSlideWidth
      }

      if (this.swapperSlideWidth) {
        return 1 + 31 / this.swapperSlideWidth
      }

      return 4
    },
  },

  methods: {
    getSwapperWidth() {
      this.swapperWidth = document.querySelector('.roskaCardImagesMobileSwiper')?.clientWidth
      this.swapperSlideWidth = document.querySelector('.roskaCardImagesMobileSlide')?.clientWidth
    },

    grabbing(e) {
      e.$el[0].classList.toggle('active')
    },
  },

  mounted() {
    this.getSwapperWidth()

    window.addEventListener('resize', this.getSwapperWidth)

    document.onload = this.getSwapperWidth
    window.onload = this.getSwapperWidth

    setTimeout(this.getSwapperWidth, 100)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getSwapperWidth)
  },
}
</script>

<style scoped lang="sass">
.swiper
  width: 100%
  padding-right: 20px !important
  padding-left: 20px !important
  cursor: grab

  &.active
    cursor: grabbing

  .slide
    width: 100%

    .image
      +background-image-settings()
      +border-radius(20px)
      width: 100%
      padding-top: calc(100% * 400 / 289)
</style>
