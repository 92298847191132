<template>
  <div class="roska-404">
    <h1 class="title">404 PAGE NOT FOUND</h1>
  </div>
</template>

<script>
export default {
  name: "roska-404"
}
</script>

<style scoped lang="sass">
.roska-404
  padding: 150px 20px

  .title
    font-size: 60px
    line-height: 150%
    text-align: center
</style>
