<template>
  <div class="roska-signIn" @click="closeModal">
    <div class="modal-window">
      <h1 class="title">LOGIN</h1>

      <div class="form">
        <main>
          <div class="inputs">
            <input
                :class="{
                  'error': error
                }"
                type="text"
                name="email"
                id="email"
                placeholder="E-Mail"
                @focus="error = false"
            >

            <input
                :class="{
                  'error': error
                }"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                @focus="error = false"
            >
          </div>

          <h5 class="forgot" @click="openSignUpModal">Not registered?</h5>

          <div class="remember" @click="rememberMe = !rememberMe">
            <div
                class="checkbox"
                :class="{
                  'active': rememberMe
                }"
            ></div>

            <h5 class="title">Remember me</h5>
          </div>
        </main>

        <input
            class="submit"
            type="submit"
            value="Login"
            @click="signIn"
        >
      </div>

<!--      <hr>-->

<!--      <button class="google">-->
<!--        <span class="icon"></span>-->

<!--        <span class="title">Continue with google</span>-->
<!--      </button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-signIn",

  data() {
    return {
      rememberMe: false,

      loading: false,
      error: false,
    }
  },

  methods: {
    openSignUpModal() {
      this.$emit('closeModal')
      this.$emit('openSignUpModal')
    },

    signIn() {
      if (this.loading) {
        return
      }

      if (!this.validateMail()) {
        return
      }

      if (!this.validateUserPassword()) {
        return
      }

      const email = document.getElementById('email').value
      const password = document.getElementById('password').value

      this.login(email, password)
    },

    async login(email, password) {
      this.loading = true

      await this.axios.post("https://api.roskainc.com/api/store/v1/users/login", {
        email: email.toString(),
        password: password.toString(),
      })
          .then(response => {
            this.$emit('updateUserId', response.data.user_id)

            this.$emit('saveSignIn', email, password, this.rememberMe)

            this.$emit('closeModal')
          })
          .catch(() => {
            this.error = true
          })

      setTimeout(() => {
        this.loading = false
      }, 3000)
    },

    validateMail() {
      const mailInput = document.getElementById('email')

      return mailInput.value.length > 0

      // return this.validateMailAddress(mailInput.value)
    },

    // validateMailAddress(mailAddress) {
    //   return String(mailAddress)
    //       .toLowerCase()
    //       .match(
    //           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //       )
    // },

    validateUserPassword() {
      const passwordInput = document.getElementById('password')

      return passwordInput.value.length > 0

      // return this.validatePassword(passwordInput.value)
    },

    // validatePassword(password) {
    //   return String(password)
    //       .match(/^[A-Za-z]\w{7,14}$/)
    // },

    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.$emit('closeModal')
      }
    },
  },
}
</script>

<style scoped lang="sass">
.roska-signIn
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.9)
  z-index: 9999
  display: flex
  align-items: center
  justify-content: center

  .modal-window
    display: flex
    flex-direction: column
    gap: 20px
    align-items: center
    background-color: white
    width: 100%
    +border-radius(20px)

    @media (min-width: $desktopScreenMinWidth)
      max-width: 440px
      padding: 60px 70px

    @media (max-width: $mobileScreenMaxWidth)
      max-width: 340px
      padding: 30px 20px

    .title
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 60px
        line-height: 72px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 48px
        line-height: 58px

    .form
      display: flex
      flex-direction: column
      align-items: center
      gap: 20px
      width: 100%

      main
        display: flex
        flex-direction: column
        align-items: center
        gap: 16px
        width: 100%

        .inputs
          display: flex
          flex-direction: column
          gap: 20px
          width: 100%

          input
            padding: 10px 20px
            text-align: center
            border: 1px solid $lightgray
            width: 100%
            +border-radius(999px)
            font-family: Inter, "Calibri Light", sans-serif
            font-weight: 400
            font-size: 17px
            line-height: 150%

            &::placeholder
              font-family: Inter, "Calibri Light", sans-serif
              font-weight: 400
              font-size: 17px
              line-height: 150%
              color: $text-lightgray

            &.error
              border: 1px solid $crimson-red

        .forgot
          font-size: 15px
          line-height: 150%
          color: $crimson_red
          text-align: center
          cursor: pointer

        .remember
          display: flex
          gap: 10px
          align-items: center
          justify-content: center
          +user-select(none)
          cursor: pointer

          .checkbox
            width: 20px
            height: 20px
            +border-radius(6px)
            border: 1px solid $lightgray

            &.active
              border: none
              +background-image-settings()
              background-image: url("/src/assets/images/svg/checkbox.svg")

          .title
            font-size: 15px
            line-height: 150%

      .submit
        padding: 10px 20px
        background-color: $crimson_red
        +border-radius(999px)
        width: 100%
        font-family: Inter, "Calibri Light", sans-serif
        font-weight: 600
        font-size: 17px
        line-height: 150%
        color: white
        cursor: pointer

    hr
      width: 100%
      height: 1px
      background-color: $lightgray

    .google
      padding: 10px 20px
      display: flex
      justify-content: center
      align-items: center
      gap: 10px
      background-color: transparent
      border: 1px solid $lightgray
      +border-radius(999px)
      width: 100%
      cursor: pointer

      .icon
        width: 24px
        height: 24px
        +background-image-settings()
        background-image: url("/src/assets/images/svg/google-icon.svg")

      .title
        font-family: Inter, "Calibri Light", sans-serif
        font-weight: 400
        font-size: 17px
        line-height: 150%
        color: $text-lightgray
</style>
