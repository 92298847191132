<template>
  <div
      v-if="productsList.length > 0"
      class="roska-main-popular"
  >
    <h1 class="title">POPULAR</h1>

    <div class="items">
      <roska-product-card
          v-for="product in productsList.slice(0, 3)"
          :key="product.id"

          :like-button="false"
          :add-to-bag-button="false"

          :article="product.id"
          :src="`https://media.roskainc.com/media/${product.image1}`"
          :title="product.title"
          :cost="product.price"
          :old-cost="product.old_price > 0 ? product.old_price : null"
      />

      <router-link to="/catalog">
        <div class="view-all">
          <div class="round">
            <div class="redline">
              <div class="button">
                <h4>VIEW<br>ALL</h4>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import RoskaProductCard from "@/components/UI-kit/roska-product-card";

export default {
  name: "roska-main-popular",

  components: {
    RoskaProductCard,
  },

  props: {
    productsList: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      items: [
        {
          title: "Stretch Cotton Trench Coat",
          cost: 70,
          src: "1.png",
        },

        {
          title: "Double-Knit Bomber Jacket",
          cost: 170,
          src: "2.png",
        },

        {
          title: "Bomber Jacket",
          cost: 570,
          src: "3.png",
        },
      ],
    }
  },
}
</script>

<style scoped lang="sass">
.roska-main-popular
  background-color: $gray
  display: flex
  flex-direction: column
  align-items: center

  @media (min-width: $desktopScreenMinWidth)
    padding-top: 65px
    padding-bottom: 150px
    gap: 60px

  @media (max-width: $mobileScreenMaxWidth)
    padding-top: 25px
    padding-bottom: 50px
    gap: 30px

  .title
    @media (min-width: $desktopScreenMinWidth)
      font-size: 100px
      line-height: 120px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 48px
      line-height: 58px

  .items
    display: flex
    gap: 20px

    @media (min-width: $desktopScreenMinWidth)

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      align-items: flex-end

    .item
      display: flex
      flex-direction: column
      gap: 20px
      width: 289px
      justify-content: flex-start

      .item-image
        width: 289px
        height: 400px
        +background-image-settings()
        +border-radius(20px)
        overflow: hidden
        position: relative
        pointer-events: none

        &:after
          background-color: $dark-gray
          width: 100%
          height: 100%
          content: ""
          display: block
          position: absolute
          top: 0
          left: 0

      .item-description
        display: flex
        flex-direction: column
        gap: 10px
        height: 100%
        flex: 1
        justify-content: space-between

        .item-name
          font-size: 20px
          line-height: 110%

        .item-cost
          font-size: 20px
          line-height: 130%

    .view-all
      @media (min-width: $desktopScreenMinWidth)
        width: 289px
        height: 400px
        background-color: $bej
        +border-radius(20px)
        position: relative

      @media (max-width: $mobileScreenMaxWidth)

      .round
        @media (min-width: $desktopScreenMinWidth)
          background-color: $gray
          width: 180px
          height: 180px
          left: -10px
          bottom: -10px
          position: absolute
          +border-radius(50% 50% 50% 0)
          padding: 10px

        @media (max-width: $mobileScreenMaxWidth)
          width: 108px
          height: 108px

        .redline
          width: 100%
          height: 100%
          +border-radius(50%)
          cursor: pointer
          +user-select(none)

          @media (min-width: $desktopScreenMinWidth)
            border: 3px solid $crimson-red
            padding: 3px

          @media (max-width: $mobileScreenMaxWidth)
            border: 2px solid $crimson-red
            padding: 2px

          .button
            width: 100%
            height: 100%
            +border-radius(50%)
            display: flex
            align-items: center
            justify-content: center
            text-align: center

            @media (min-width: $desktopScreenMinWidth)
              font-size: 24px
              line-height: 29px
              border: 2px solid white

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 16px
              line-height: 19px
              border: 1px solid black
</style>
