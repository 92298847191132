import VueRouter from "vue-router";

import roskaMain from "@/components/templates/main/roska-main";
import roskaCatalog from "@/components/templates/catalog/roska-catalog";
import roskaWishlist from "@/components/templates/wishlist/roska-wishlist";
import roskaCard from "@/components/templates/product-card/roska-card";
import roskaBasket from "@/components/templates/basket/roska-basket";
import roskaAccount from "@/components/templates/account/roska-account";
import roskaAccountOrders from "@/components/templates/account/orders/roska-account-orders";
import roskaAccountPurchase from "@/components/templates/account/purchase/roska-account-purchase";
import roskaAccountProfile from "@/components/templates/account/profile/roska-account-profile";
import roska404 from "@/components/templates/404-page/roska-404";
import RoskaSale from "@/components/templates/sale/roska-sale";
import RoskaCollection from "@/components/templates/collection/roska-collection";
import RoskaSearch from "@/components/templates/search/roska-search";
import RoskaTestPage from "@/components/templates/test-page/roska-test-page";
import RoskaAccountOrdersOrder from "@/components/templates/account/order/roska-account-orders-order.vue";

// import testPayment from "@/components/templates/main/testPayment/testPayment.vue";

export default new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "main",
            component: roskaMain,
            meta: {
                title: "ROSKA - Main"
            },
        },

        {
            path: "/catalog",
            name: "catalog",
            component: roskaCatalog,
            meta: {
                title: "ROSKA - Catalog"
            },
        },

        {
            path: "/catalog/:article",
            name: "card",
            component: roskaCard,
            meta: {
                title: "ROSKA"
            },
        },

        {
            path: "/wishlist",
            name: "wishlist",
            component: roskaWishlist,
            meta: {
                title: "ROSKA - Wishlist"
            },
        },

        {
            path: "/basket",
            name: "basket",
            component: roskaBasket,
            meta: {
                title: "ROSKA - Basket"
            },
        },

        {
            path: "/sale",
            name: "sale",
            component: RoskaSale,
            meta: {
                title: "ROSKA - Sale"
            },
        },

        {
            path: "/collection",
            name: "collection",
            component: RoskaCollection,
            meta: {
                title: "ROSKA - Collection"
            },
        },

        {
            path: "/search",
            name: "search",
            component: RoskaSearch,
            meta: {
                title: "ROSKA - Search"
            },
        },

        {
            path: "/test-page",
            name: "testPage",
            component: RoskaTestPage,
            meta: {
                title: "ROSKA - Test page"
            },
        },

        {
            path: "/account",
            component: roskaAccount,
            meta: {
                title: "ROSKA - Account"
            },
            children: [
                {
                    path: "orders",
                    name: "orders",
                    component: roskaAccountOrders,
                    meta: {
                        title: "ROSKA - Account - Order History"
                    },
                },

                {
                    path: "purchase",
                    name: "purchase",
                    component: roskaAccountPurchase,
                    meta: {
                        title: "ROSKA - Account - Purchase"
                    },
                },

                {
                    path: "",
                    name: "profile",
                    component: roskaAccountProfile,
                    meta: {
                        title: "ROSKA - Profile"
                    },
                },

                {
                    path: "orders/:orderId",
                    name: "order",
                    component: RoskaAccountOrdersOrder,
                    meta: {
                        title: "ROSKA - Account - Order History"
                    },
                },
            ],
        },

        // {
        //     path: "/test-payment",
        //     name: "test-payment",
        //     component: testPayment,
        //     meta: {
        //         title: "ROSKA - Payment Testing"
        //     },
        // },

        {
            path: "/:pathMatch(.*)*",
            name: "404",
            component: roska404,
            meta: {
                title: "ROSKA - 404 Page Not Found"
            },
        },
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return {
                x: 0,
                y: 0,
                el: to.hash,
                behavior: "smooth",
            }
        }
    }
})
