<template>
  <div class="roska-main-promo15">
    <div class="hideLine">
      <section>
        <aside>
          <div class="info">
            <h5>Get 15% off<br>on first order</h5>

            <roska-send-email @click="checkPromo"/>

            <div
                class="message"
                :class="{
                  red: textColor === 'red',
                  green: textColor === 'green',
                }"
            >
              <h4>{{ message}}</h4>
            </div>
          </div>

          <div class="image">
            <div class="line"></div>

            <img src="../../../assets/images/promo/image1.png" alt="Man in ROSKA clothing" width="554" height="309">
          </div>
        </aside>
      </section>
    </div>
  </div>
</template>

<script>
import roskaSendEmail from "@/components/UI-kit/roska-sendEmail";

export default {
  name: "roska-main-promo15",

  components: {
    roskaSendEmail,
  },

  data() {
    return {
      message: null,
      textColor: null,
    }
  },

  methods: {
    async checkPromo(mail) {
      if (!mail) {
        this.textColor = null
        this.message = null

        return
      }

      await this.axios.post("https://api.roskainc.com/api/store/v1/users/coupon", {
        email: mail,
      })
          .then(response => {
            console.log('[AXIOS POST] Send Promo Code To Mail', response.data)

            this.textColor = 'green'

            this.message = "The email with the promo code was successfully sent."
          })
          .catch(error => {
            console.log('[AXIOS POST ERROR] Send Promo Code To Mail', JSON.parse(error.request.response).detail[0].msg)

            this.textColor = 'red'

            if (!JSON.parse(error.request.response).detail[0].msg) {
              this.message = "Already used."
            } else {
              const message = JSON.parse(error.request.response).detail[0].msg
              this.message = message[0].toUpperCase() + message.slice(1) + '.'
            }
          })
    }
  },
}
</script>

<style scoped lang="sass">
.roska-main-promo15
  background-color: black
  display: flex
  justify-content: center
  color: $text-white

  @media (min-width: $desktopScreenMinWidth)
    padding: 150px 0
    background-color: black

  @media (max-width: $mobileScreenMaxWidth)
    padding: 50px 0
    background-color: $brown

  .hideLine
    @media (min-width: $desktopScreenMinWidth)
      overflow: hidden
      width: 1216px

    @media (max-width: $mobileScreenMaxWidth)
      width: 320px

    section
      padding: 4px

      @media (min-width: $desktopScreenMinWidth)
        border: 4px solid $crimson-red

      @media (max-width: $mobileScreenMaxWidth)
        border: 3px solid $crimson-red

      aside
        display: flex
        justify-content: space-between

        @media (min-width: $desktopScreenMinWidth)
          padding: 32px 31px
          border: 2px solid white

        @media (max-width: $mobileScreenMaxWidth)
          padding: 32px 12px 31px
          border: 1px solid white

        .info
          display: flex
          flex-direction: column

          @media (min-width: $desktopScreenMinWidth)
            padding: 40px
            gap: 50px

          @media (max-width: $mobileScreenMaxWidth)
            align-items: center
            text-align: center
            gap: 30px

          > *:first-child
            @media (min-width: $desktopScreenMinWidth)
              font-size: 50px
              line-height: 61px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 30px
              line-height: 36px

          .message
            font-size: 18px

            &.red
              color: $crimson_red

            &.green
              color: $green

        .image
          @media (min-width: $desktopScreenMinWidth)
            display: flex
            align-items: center
            position: relative
            pointer-events: none

          @media (max-width: $mobileScreenMaxWidth)
            display: none

          .line
            width: 32px
            height: 100vh
            background-color: white
            position: absolute
            right: 100%
            +box-sizing(content-box)
            transform: rotate(-17.29deg)
</style>
