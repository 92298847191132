<template>
  <div class="roska-catalog-filter-menu-size">
    <roska-size-round
        v-for="size in Object.keys(sizes)"
        :key="size"
        :clickable="true"
        :title="size"
        :active="sizes[size]"
        @click="sizes[size] = !sizes[size]"
    />
  </div>
</template>

<script>
import roskaSizeRound from "@/components/UI-kit/size-round/roska-size-round";

export default {
  name: "roska-catalog-filter-menu-size",

  components: {
    roskaSizeRound,
  },

  data() {
    return {
      sizes: {
        XS: false,
        S: false,
        M: false,
        L: false,
        XL: false,
      },
    }
  },
}
</script>

<style scoped lang="sass">
.roska-catalog-filter-menu-size
  display: flex
  flex-wrap: wrap
  gap: 10px
</style>
