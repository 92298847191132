<template>
  <div
      class="roska-size-round"
      :class="{
        'disabled': disabled,
        'active': active,
        'clickable': clickable,
      }"
      @click="$emit('click')"
  >
    <h5 class="title">{{ title.toUpperCase() }}</h5>
  </div>
</template>

<script>
export default {
  name: "roska-size-round",

  props: {
    title: {
      type: String,
      default: "SIZE",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="sass">
.roska-size-round
  padding: 7px 19px
  +border-radius(999px)
  border: 1px solid black

  &.clickable
    cursor: pointer

  &.disabled
    color: $text-disabled
    border-color: $paginationGray

  &.active
    border: 1px solid $crimson_red
    background-color: $crimson_red
    color: white

  .title
    font-size: 17px
    line-height: 150%
</style>
