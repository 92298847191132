<template>
  <div
      class="roska-like"
      @click="$emit('like')"
      :class="{
        'active': liked,
        'whiteRound': whiteRound,
      }"
      :style="`padding: ${ padding }px`"
  >
    <div class="round">
      <div class="image"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-like",

  props: {
    whiteRound: {
      type: Boolean,
      default: false,
    },

    padding: {
      type: Number,
      default: 15,
    },

    liked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="sass">
.roska-like
  position: absolute
  cursor: pointer
  pointer-events: all
  +border-radius(0 20px 0 0)
  z-index: 9

  .round
    width: 32px
    height: 32px

    .image
      +background-image-settings()
      background-image: url("/src/assets/images/catalog/items/like.svg")
      width: 100%
      height: 100%

  &.active
    .image
      background-image: url("/src/assets/images/catalog/items/liked.svg")

  &.whiteRound
    .round
      background-color: white
      +border-radius(50%)
      padding: 6px
</style>
