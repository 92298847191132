var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-account"},[_c('div',{staticClass:"menu"},[_c('router-link',{class:{
            'active': _vm.page === 'orders'
        },attrs:{"to":{ path: '/account/orders' }}},[_c('h5',[_vm._v("ORDER HISTORY")])]),_c('router-link',{class:{
            'active': _vm.page === 'purchase'
        },attrs:{"to":{ path: '/account/purchase' }}},[_c('h5',[_vm._v("PURCHASE AMOUNT")])]),_c('router-link',{class:{
            'active': _vm.page === null
        },attrs:{"to":{ path: '/account' }}},[_c('h5',[_vm._v("PROFILE")])])],1),_c('router-view',{staticClass:"page",attrs:{"userData":_vm.userData,"userId":_vm.userId},on:{"signOut":function($event){return _vm.$emit('signOut')},"updateUserData":newUserData => _vm.$emit('updateUserData', newUserData)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }