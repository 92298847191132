<template>
  <div class="roska-basket">
    <roska-breadcrumbs :crumbs="crumbs" />

    <div class="basket">
      <h1 class="title">{{ title }}</h1>

      <main>
        <div
            v-if="!loading"
            class="items"
        >
          <div
              v-for="product in bag"
              :key="`roska-basket-${product.product_id}-${product.stripe_id}-${product.size}-${product.color}`"
              class="item"
          >
            <div class="card">
              <router-link
                  :to="{ path: `/catalog/${ product.product_id }` }"
                  v-slot="{ navigate }"
                  custom
              >
                <div
                    class="image"
                    :style="{ backgroundImage: `url(https://media.roskainc.com/media/${ productsList.find(item => item.id === product.product_id)['image1'] })` }"
                    @click="navigate"
                ></div>
              </router-link>

              <div class="main">
                <router-link
                    :to="{ path: `/catalog/${ product.product_id }` }"
                    v-slot="{ navigate }"
                    custom
                >
                  <h1
                      class="title"
                      @click="navigate"
                  >{{ productsList.find(item => item.id === product.product_id).title }}</h1>
                </router-link>

                <div class="wrapper">
                  <div class="cost">
                    <h3>€{{ productsList.find(item => item.id === product.product_id)['price'].toFixed(2) }}</h3>

                    <h3 v-if="productsList.find(item => item.id === product.product_id)['old_price']">€{{ productsList.find(item => item.id === product.product_id)['old_price'].toFixed(2) }}</h3>
                  </div>

                  <div class="other">
                    <div class="size">
                      <h3>SIZE</h3>

                      <roska-size-round
                          :title="product.size"
                          :clickable="false"
                      />
                    </div>

                    <div class="color">
                      <h3>COLOR</h3>

                      <roska-color-round
                          :colors-array="product.color"
                      />
                    </div>
                  </div>
                </div>

                <div class="buttons only-ds">
                  <!-- @TODO Зачем нужна кнопка EDIT в корзине? -->
                  <h5>EDIT</h5>

                  <h5 @click="$emit('editBag', product.product_id, product.stripe_id, product.color, product.size, 0)">REMOVE</h5>
                </div>
              </div>
            </div>

            <div class="description">
              <roska-volume
                  :volume="product.quantity"
                  :min-value="0"
                  :maxValue="Number(productsAvailability[product.product_id].find(el => el.color === product.color && el.size === product.size).count)"
                  @update="newVolume => $emit('editBag', product.product_id, product.stripe_id, product.color, product.size, newVolume)"
              />

              <div class="buttons only-mb">
                <!-- @TODO Зачем нужна кнопка EDIT в корзине? -->
                <h5>EDIT</h5>

                <h5 @click="$emit('editBag', product.product_id, product.stripe_id, product.color, product.size, 0)">REMOVE</h5>
              </div>

              <h3 class="totalCost">€{{ (productsList.find(item => item.id === product.product_id)['price'] * product.quantity).toFixed(2) }}</h3>
            </div>
          </div>
        </div>

        <div class="about">
          <h3 class="title">ORDER SUMMARY</h3>

          <div class="info">
            <div class="subtotal">
              <h5>SUBTOTAL</h5>

              <h5>€{{ subtotalSum.toFixed(2) }}</h5>
            </div>

            <div class="subtotal" v-if="this.promoMessage === 'UNACTIVATED' && subtotalSum > 0">
              <h5>DISCOUNT</h5>

              <h5>- €{{ (subtotalSum / 100 * 15).toFixed(2) }}</h5>
            </div>

            <div class="shipping">
              <h5>SHIPPING</h5>

              <h5>{{ shippingCost ? `€${shippingCost.toFixed(2)}` : 'FREE' }}</h5>
            </div>

            <div class="promo">
              <input type="text" placeholder="PROMO CODE" class="input" v-model="promo" @change="handlerPromoCheckButton">

              <div class="button" @click="handlerPromoCheckButton"></div>
            </div>

            <h5
                class="message"
                v-if="promoMessage"
                v-html="promoMessage"
                :class="{
                  bad: promoMessage === 'ACTIVATED' || promoMessage === 'UNAVAILABLE',
                  good: promoMessage === 'UNACTIVATED',
                }"
            />

            <div class="total">
              <h3>TOTAL</h3>

              <h3 v-if="promoMessage === 'UNACTIVATED' && subtotalSum > 0">€{{ (subtotalSum + shippingCost - subtotalSum / 100 * 15).toFixed(2) }}</h3>
              <h3 v-else>€{{ (subtotalSum + shippingCost).toFixed(2) }}</h3>
            </div>
          </div>
        </div>
      </main>
    </div>

    <div class="order">
      <h1 class="title">ORDER DETAILS</h1>

      <main>
        <div class="part">
          <h3 class="title">CONTACT INFORMATION</h3>

          <div class="wrapper">
            <input type="text" placeholder="NAME" v-model="name">

            <input type="text" placeholder="E-MAIL" v-model="email">
          </div>
        </div>

        <div class="part">
          <h3 class="title">SHIPPING ADDRESS</h3>

          <div class="wrapper">
            <div class="input">
              <country-select v-model="country" :country="country" topCountry="US" :countryName="true" />
            </div>

            <div class="input">
              <region-select v-model="state" :country="country" :region="state" :countryName="true" :regionName="true" />
            </div>

            <input type="text" placeholder="CITY" v-model="city" >
          </div>

          <div class="wrapper">
            <input type="text" placeholder="STREET" v-model="street" >

            <input type="text" placeholder="ZIP CODE" v-model="zipcode" >
          </div>
        </div>

        <roska-round-button
            :title="'ORDER'"
            :mobile="false"
            @click="submit"
        />
      </main>
    </div>
  </div>
</template>

<script>
import roskaBreadcrumbs from "@/components/UI-kit/roska-breadcrumbs";
import roskaVolume from "@/components/UI-kit/volume/roska-volume";
import roskaRoundButton from "@/components/UI-kit/roska-round-button";
import roskaColorRound from "@/components/UI-kit/color-round/roska-color-round";
import roskaSizeRound from "@/components/UI-kit/size-round/roska-size-round";

export default {
  name: "roska-basket",

  components: {
    roskaBreadcrumbs,
    roskaVolume,
    roskaRoundButton,
    roskaColorRound,
    roskaSizeRound,
  },

  props: {
    bag: {
      type: Array,
      default: () => {
        return []
      },
    },

    userData: {
      type: Object,
      default() {
        return {}
      },
    },

    productsList: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      loading: true,

      shippingCost: 0,

      productsAvailability: {},

      promo: null,
      promoMessage: null,

      name: '',
      email: '',
      country: '',
      state: '',
      city: '',
      street: '',
      zipcode: '',
    }
  },

  computed: {
    crumbs() {
      return [{
        title: "CART",
      }]
    },

    subtotalSum() {
      if (this.productsList.length === 0) {
        return 0
      }

      const initialValue = 0

      return this.bag.reduce(
          (accumulator, currentValue) => {
            const price = this.productsList?.find(product => product.id === currentValue.product_id).price

            return accumulator + price * currentValue.quantity
          },
          initialValue
      )
    },

    title() {
      const length = this.bag.length

      if (length) {
        if (length > 1) {
          return `YOU HAVE ${ length } ITEMS IN YOUR BASKET`
        } else {
          return `YOU HAVE 1 ITEM IN YOUR BASKET`
        }
      } else {
        return "YOUR BASKET IS EMPTY"
      }
    },

    articleCollection() {
      return this.bag.map(product => product.product_id)
    },
  },

  methods: {
    async handlerPromoCheckButton() {
      if (this.promo === '') {
        this.promoMessage = null
        return
      }

      if (!this.promo) {
        return
      }

      await this.promoCheck()
    },

    async promoCheck() {
      await this.axios.put("https://api.roskainc.com/api/store/v1/users/coupon", {
        coupon: this.promo,
      })
          .then(response => {
            console.log('[AXIOS PUT] Checking Promo Code', response.data)

            const promoInfo = response.data

            if (!promoInfo.status) {
              this.promoMessage = 'UNAVAILABLE'
              return
            }

            this.promoMessage = promoInfo.status
          })
          .catch(error => {
            console.log("[AXIOS PUT ERROR] Checking Promo Code", error)

            this.promoMessage = 'UNAVAILABLE'
          })
    },

    submit() {
      if (this.bag.length === 0) {
        return
      }

      console.log(this.bag)

      if (this.userData.id === undefined) {
        this.createGuestOrder()
      } else {
        this.createUserOrder()
      }
    },

    async getProductAvailability(productId) {
      await this.axios.get("https://api.roskainc.com/api/store/v1/products/readAvailability", {
        params: {
          'product_uuid': productId,
        },
      })
          .then(response => {
            this.productsAvailability[productId] = response.data

            console.log('GLOBAL GET PRODUCT AVAILABILITY', response.data)
          })
          .catch(error => {
            console.log("Getting Product Availability Error", error)
          })
    },

    async createGuestOrder() {
      await this.axios.post("https://api.roskainc.com/api/store/v1/users/orders", {
        value: this.subtotalSum,
        name: this.name,
        products: this.bag,
        email: this.email,
        country: this.country,
        state: this.state,
        city: this.city,
        street: this.street,
        zip_code: this.zipcode,
        coupon: this.promoMessage === 'UNACTIVATED' ? this.promo : '',
      })
          .then(response => {
            console.log('POST CREATE GUEST ORDER', response.data)

            const url = response.data.url

            this.$emit('clearBag')

            window.open(url, '_blank').focus();
          })
          .catch(error => {
            console.log("POST CREATING GUEST ORDER ERROR", error)
          })
    },

    async createUserOrder() {
      await this.axios.post("https://api.roskainc.com/api/store/v1/users/orders", {
        user_id: this.userData.id,
        value: this.subtotalSum,
        name: this.name,
        email: this.email,
        country: this.country,
        state: this.state,
        city: this.city,
        street: this.street,
        zip_code: this.zipcode,
        coupon: this.promoMessage === 'UNACTIVATED' ? this.promo : '',
      })
          .then(response => {
            console.log('POST CREATE GUEST ORDER', response.data)

            const url = response.data.url

            window.open(url, '_blank').focus();
          })
          .catch(error => {
            console.log("POST CREATING GUEST ORDER ERROR", error)
          })
    },
  },

  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) {
          return
        }

        this.email = val.email || ''
        this.country = val.country || ''
        this.state = val.state || ''
        this.city = val.city || ''
        this.street = val.street || ''
        this.zipcode = val.zip_code || ''
      },
    },

    bag: {
      deep: true,
      immediate: true,
      async handler(newBag, oldBag) {
        this.loading = true

        if (oldBag !== undefined) {
          if (newBag.length === oldBag.length) {
            const isBagsEquals = newBag.map(product => product.product_id).every(product => oldBag.map(oldProduct => oldProduct.product_id).includes(product))

            if (isBagsEquals) {
              this.loading = false
              return
            }
          }
        }

        this.productsAvailability = {}

        for(const id of this.articleCollection){
          await this.getProductAvailability(id)
        }

        this.loading = false
      },
    },
  },
}
</script>

<style scoped lang="sass">
.roska-basket
  @media (min-width: $desktopScreenMinWidth)
    padding-bottom: 130px
    width: 1216px

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%
    padding-bottom: 60px

  .basket
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 60px
      padding-bottom: 100px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 40px
      padding-left: 20px
      padding-right: 20px
      padding-bottom: 60px

    > .title
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 60px
        line-height: 72px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 30px
        line-height: 36px

    main
      display: flex

      @media (min-width: $desktopScreenMinWidth)
        gap: 46px
        align-items: flex-start
        justify-content: flex-end

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px
        flex-direction: column
        align-items: center

      .items
        display: flex
        flex-direction: column
        width: 100%

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px
          flex: 1

        @media (max-width: $mobileScreenMaxWidth)
          gap: 16px

        .item
          display: flex
          gap: 20px

          @media (min-width: $desktopScreenMinWidth)
            justify-content: space-between
            width: 100%
            align-items: center

          @media (max-width: $mobileScreenMaxWidth)
            gap: 16px
            flex-direction: column
            padding-bottom: 16px
            border-bottom: 1px solid $lightgray

            &:last-child
              padding-bottom: unset
              border-bottom: unset

          .card
            display: flex
            align-items: flex-start

            @media (min-width: $desktopScreenMinWidth)
              gap: 40px
              width: 100%

            @media (max-width: $mobileScreenMaxWidth)
              gap: 20px

            .image
              +background-image-settings()

              @media (min-width: $desktopScreenMinWidth)
                width: 289px
                height: 289px
                +border-radius(12px)

              @media (max-width: $mobileScreenMaxWidth)
                width: 100px
                height: 100px
                +border-radius(10px)

            .main
              display: flex
              flex-direction: column

              @media (min-width: $desktopScreenMinWidth)
                gap: 20px

              @media (max-width: $mobileScreenMaxWidth)
                gap: 6px

              > .title,
              > a > .title
                line-height: 110%

                @media (min-width: $desktopScreenMinWidth)
                  font-size: 30px

                @media (max-width: $mobileScreenMaxWidth)
                  font-size: 20px

              .wrapper
                display: flex
                flex-direction: column

                @media (min-width: $desktopScreenMinWidth)
                  gap: 16px

                @media (max-width: $mobileScreenMaxWidth)
                  gap: 10px

                .cost
                  display: flex
                  gap: 20px
                  line-height: 130%

                  @media (min-width: $desktopScreenMinWidth)
                    font-size: 17px

                  @media (max-width: $mobileScreenMaxWidth)
                    font-size: 15px

                  > *
                    &:nth-child(2)
                      text-decoration: line-through
                      color: $text-lightgray

                .other
                  display: flex
                  line-height: 150%
                  align-items: center

                  @media (min-width: $desktopScreenMinWidth)
                    gap: 30px
                    font-size: 17px

                  @media (max-width: $mobileScreenMaxWidth)
                    gap: 10px
                    font-size: 15px

                  .size
                    display: flex
                    align-items: center

                    @media (min-width: $desktopScreenMinWidth)
                      gap: 12px

                    @media (max-width: $mobileScreenMaxWidth)
                      gap: 4px

                    > *
                      &:last-child
                        padding: 8px 20px
                        border: 1px solid black
                        +border-radius(999px)

                  .color
                    display: flex
                    align-items: center

                    @media (min-width: $desktopScreenMinWidth)
                      gap: 12px

                    @media (max-width: $mobileScreenMaxWidth)
                      gap: 4px

                    .round
                      width: 24px
                      height: 24px
                      +border-radius(50%)
                      background-color: #715C53

              .buttons
                color: $text-lightgray
                display: flex
                gap: 30px
                font-size: 15px
                line-height: 150%

                > *
                  cursor: pointer

          .description
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            gap: 10px

            .buttons
              display: flex
              flex-wrap: wrap
              justify-content: center
              gap: 10px
              font-size: 15px
              line-height: 150%
              color: $text-lightgray

              > *
                cursor: pointer

            .totalCost
              font-size: 17px
              line-height: 130%

      .about
        display: flex
        flex-direction: column
        align-items: center
        +border-radius(10px)
        border: 2px solid $crimson_red
        padding: 24px 30px 26px
        gap: 20px
        background-color: white

        @media (min-width: $desktopScreenMinWidth)

        @media (max-width: $mobileScreenMaxWidth)
          width: 320px

        > .title
          text-align: center
          font-size: 22px
          line-height: 150%

        .info
          display: flex
          flex-direction: column
          gap: 10px
          width: 100%
          font-size: 17px
          line-height: 150%

          > *
            display: flex
            justify-content: space-between

            &:last-child
              padding-top: 16px
              border-top: 1px solid black

            &.promo
              display: flex
              background-color: $gray
              +border-radius(8px)
              overflow: hidden

              .input
                background-color: $gray
                padding-left: 10px
                width: 100%
                flex: 1
                font-family: Inter, "Calibri Light", sans-serif
                font-weight: 400
                font-size: 15px
                line-height: 150%

                &::placeholder
                  color: $text-gray

              .button
                width: 40px
                height: 40px
                +background-image-settings()
                background-image: url("/src/assets/images/svg/button.svg")
                cursor: pointer

            &.message
              display: flex
              justify-content: center
              width: 100%
              text-align: center
              font-weight: 600
              font-size: 15px
              line-height: 100%

              &.bad
                color: $crimson_red

              &.good
                color: $green

  .order
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 60px

    @media (max-width: $mobileScreenMaxWidth)
      padding-left: 20px
      padding-right: 20px
      gap: 30px

    > .title
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 60px
        line-height: 72px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 30px
        line-height: 36px

    main
      display: flex
      flex-direction: column
      gap: 40px

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        align-items: center

      .part
        display: flex
        flex-direction: column
        gap: 20px
        width: 100%

        > .title
          line-height: 150%

          @media (min-width: $desktopScreenMinWidth)
            font-size: 20px

          @media (max-width: $mobileScreenMaxWidth)
            font-size: 22px
            text-align: center

        .wrapper
          gap: 20px

          @media (min-width: $desktopScreenMinWidth)
            display: flex
            flex-wrap: wrap

          @media (max-width: $mobileScreenMaxWidth)
            display: grid
            grid-template-columns: repeat(auto-fill, minmax(max(250px), 1fr))

          input
            border: 1px solid $red
            +border-radius(999px)
            padding: 10px 20px
            font-size: 17px
            line-height: 150%
            font-family: Inter, "Calibri Light", sans-serif
            height: 26px
            box-sizing: content-box

            @media (min-width: $desktopScreenMinWidth)
              width: 250px

            @media (max-width: $mobileScreenMaxWidth)
              text-align: center

            &::placeholder
              color: $text-lightgray

          .input
            +border-radius(999px)
            border: 1px solid $red
            font-family: Inter, "Calibri Light", sans-serif
            font-size: 17px
            line-height: 150%
            overflow: hidden
            cursor: pointer

            @media (min-width: $desktopScreenMinWidth)
              width: 273px

            @media (max-width: $mobileScreenMaxWidth)
              *
                text-align: center

            *
              cursor: pointer

            > *
              padding: 10px 40px
              margin-right: -20px
              margin-left: -20px
              width: 100%
              box-sizing: content-box
              height: 26px
</style>
