<template>
  <div class="roska-catalog-filter-menu-price">
    <div class="inputs">
      <div class="input">
        <input
            type="number"
            v-model="inputFrom"
            placeholder="€540"
            @input="numberFromValid"
        >

        <div class="text">
          <span v-if="symbolFromValid">{{ inputFrom }}</span>
        </div>
      </div>

      <div class="input">
        <input
            type="number"
            v-model="inputTo"
            placeholder="€3510"
            @input="numberToValid"
        >

        <div class="text">
          <span v-if="symbolToValid">{{ inputTo }}</span>
        </div>
      </div>
    </div>

    <div class="range">
      <input
          type="range"
          :min="from"
          :max="to"
          step="1"
          :value="max"
          @input="maxInput"
      >

      <input
          type="range"
          :min="from"
          :max="to"
          step="1"
          :value="min"
          @input="minInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-catalog-filter-menu-cost",

  data() {
    return {
      inputFrom: null,
      inputTo: null,

      from: 0,
      to: 1000,

      min: 0,
      max: 1000,
    }
  },

  computed: {
    symbolFromValid() {
      return this.inputFrom !== null &&
          this.inputFrom.toString().length < 8 &&
          this.inputFrom !== ''
    },

    symbolToValid() {
      return this.inputTo !== null &&
          this.inputTo.toString().length < 8 &&
          this.inputTo !== ''
    },
  },

  methods: {
    maxInput(event) {
      const newMax = Math.max(this.min, event.target.value)
      event.target.value = newMax
      this.max = newMax
    },

    minInput(event) {
      const newMin = Math.min(this.max, event.target.value)
      event.target.value = newMin
      this.min = newMin
    },

    updateColor() {
      const minPos = this.min / (this.to - this.from) * 100
      const maxPos = this.max / (this.to - this.from) * 100

      let string = `linear-gradient(${
        [
          'to right',
          '#B5B5B5 0%',
          `#B5B5B5 ${ minPos }%`,
          `black ${ minPos }%`,
          `black ${ maxPos }%`,
          `#B5B5B5 ${ maxPos }%`,
          '#B5B5B5 100%',
        ].join(', ')
      })`

      document.documentElement.style.setProperty("--rangeInput", string);
    },

    numberFromValid(event) {
      this.inputFrom = Number(event.target.value || 0)
      event.target.value = this.inputFrom
    },

    numberToValid(event) {
      this.inputTo = Number(event.target.value || 0)
      event.target.value = this.inputTo
    },
  },

  watch: {
    min() {
      this.updateColor()

      this.inputFrom = this.min

      this.$emit('updatePrice', this.min, this.max)
    },

    max() {
      this.updateColor()

      this.inputTo = this.max

      this.$emit('updatePrice', this.min, this.max)
    },

    inputFrom() {
      this.min = Math.max(Math.min(this.inputFrom, this.max), this.from)
    },

    inputTo() {
      this.max = Math.min(Math.max(this.inputTo, this.min), this.to)
    },
  },
}
</script>

<style scoped lang="sass">
.roska-catalog-filter-menu-price
  display: flex
  flex-direction: column
  gap: 10px

  .inputs
    display: flex
    gap: 10px

    .input
      position: relative
      width: 100%

      input
        padding: 6px 16px 8px
        border: 1px solid $lightgray
        +border-radius(999px)
        text-align: center
        width: 100%
        font-family: Inter, "Calibri Light", sans-serif
        font-weight: 400
        font-size: 15px
        line-height: 150%
        background-color: transparent

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
          margin: 0

      .text
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        padding: 6px 16px 8px
        border: 1px solid transparent
        text-align: center
        font-family: Inter, "Calibri Light", sans-serif
        font-weight: 400
        font-size: 15px
        line-height: 150%
        z-index: -9

        span
          position: relative
          color: transparent

          &::before
            content: '€'
            display: inline
            font-family: Inter, "Calibri Light", sans-serif
            font-weight: 400
            font-size: 15px
            line-height: 150%
            position: absolute
            left: -10px
            color: black

  .range
    position: relative

    input
      -webkit-appearance: none
      background-color: transparent
      height: 16px
      display: flex
      align-items: center
      width: 100%

      &::-ms-thumb
        -webkit-appearance: none
        width: 16px
        height: 16px
        background-color: black
        +border-radius(999px)
        display: flex
        align-items: center
        margin-top: -7px
        z-index: 9

      &::-moz-range-thumb
        -webkit-appearance: none
        width: 16px
        height: 16px
        background-color: black
        +border-radius(999px)
        display: flex
        align-items: center
        margin-top: -7px
        z-index: 9

      &::-webkit-slider-thumb
        -webkit-appearance: none
        width: 16px
        height: 16px
        background-color: black
        +border-radius(999px)
        display: flex
        align-items: center
        margin-top: -7px
        z-index: 9

      &:first-of-type

        &::-ms-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: var(--rangeInput)
          height: 2px
          display: flex
          align-items: center

        &::-moz-range-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: var(--rangeInput)
          height: 2px
          display: flex
          align-items: center

        &::-webkit-slider-runnable-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: var(--rangeInput)
          height: 2px
          display: flex
          align-items: center

      &:last-of-type
        position: absolute
        top: 0
        left: 0

        &::-ms-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: transparent
          height: 2px

        &::-moz-range-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: transparent
          height: 2px

        &::-webkit-slider-runnable-track
          -webkit-appearance: none
          box-shadow: none
          border: none
          background: transparent
          height: 2px

  //.inputs
  //  display: flex
  //  gap: 10px
  //
  //  input
  //    font-family: Inter, "Calibri Light", sans-serif
  //    font-weight: 400
  //    font-size: 15px
  //    line-height: 150%
  //    width: 50%
  //    padding-top: 6px
  //    padding-bottom: 8px
  //    color: $text-lightgray
  //    display: flex
  //    text-align: center
  //    +border-radius(100px)
  //    border: 1px solid $lightgray
</style>
