<template>
  <div class="roska-sendEmail">
    <input type="text" placeholder="E-mail" v-model="mail">

    <div class="button" @click="handleButtonClick">
      <div class="triangle1">
        <div class="triangle2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-sendEmail",

  data() {
    return {
      mail: null,
    }
  },

  methods: {
    handleButtonClick() {
      this.$emit('click', this.mail)
    }
  },
}
</script>

<style scoped lang="sass">
.roska-sendEmail
  border-bottom: 1px solid white
  display: flex
  align-items: center

  input
    width: 100%
    padding: 10px
    background-color: transparent

    font-family: Inter, "Calibri Light", sans-serif
    font-weight: 400
    font-size: 20px
    line-height: 24px

    &::placeholder
      color: $text-gray

  .button
    width: 44px
    height: 44px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

    .triangle1
      +background-image-settings()
      background-image: url("/src/assets/images/svg/button_triangle_1.svg")
      width: 18px
      height: 20px
      display: flex
      align-items: center
      justify-content: center

      .triangle2
        +background-image-settings()
        background-image: url("/src/assets/images/svg/button_triangle_2.svg")
        width: 9px
        height: 9px
        position: relative
        left: -1px
</style>
