<template>
  <div
      v-if="product !== null"
      class="roska-main-welcome"
  >
    <div class="left">
      <div
          class="round-image"
          :style="{ backgroundImage: firstImageUrl }"
      ></div>
    </div>

    <div class="buttons-group only-mb">
      <div class="women" @click="redirectToWomenCatalogPage"><h4>WOMEN</h4></div>

      <div class="men" @click="redirectToManCatalogPage"><h4>MEN</h4></div>
    </div>

    <div class="description">
      <h2
          class="name"
          @click="$router.push({ path: `/catalog/${ product['id'] }` })"
      >
        <template
            v-for="(word, index) in product.title.split(' ')"
        >
          <span
              v-if="index % 2"
              :key="`roska-maim-welcome-${index}`"
              class="text-red"
          >{{ word }}</span>
          <h2
              v-else
              :key="`roska-maim-welcome-${index}`"
          >{{ word }}</h2>
        </template>
      </h2>

      <h5 class="info">{{ product.short_description.toUpperCase() }}</h5>

      <h2 class="cost">€{{ product.price.toFixed(2) }}</h2>

      <div class="buttons-group only-ds">
        <div class="women" @click="redirectToWomenCatalogPage">
          <h4>WOMEN</h4>
        </div>

        <div class="men" @click="redirectToManCatalogPage">
          <h4>MEN</h4>
        </div>
      </div>
    </div>

    <div
        class="right"
        :style="{ backgroundImage: secondImageUrl }"
        @click="$router.push({ path: `/catalog/${ product['id'] }` })"
    ></div>
  </div>
</template>

<script>
export default {
  name: "roska-main-welcome",

  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  computed: {
    firstImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image1 === null) {
        return ""
      }

      if (this.product.image1 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image1 }')`
    },

    secondImageUrl() {
      if (this.product === null) {
        return ""
      }

      if (this.product.image2 === null) {
        return ""
      }

      if (this.product.image2 === undefined) {
        return ""
      }

      return `url('https://media.roskainc.com/media/${ this.product.image2 }')`
    },
  },

  methods: {
    redirectToWomenCatalogPage() {
      this.$router.push({ path: '/catalog', query: { sex: 'women' } })
    },

    redirectToManCatalogPage() {
      this.$router.push({ path: '/catalog', query: { sex: 'man' } })
    },
  },
}
</script>

<style scoped lang="sass">
.roska-main-welcome
  display: flex

  @media (min-width: $desktopScreenMinWidth)
    padding-top: 76px
    padding-bottom: 150px
    gap: 30px
    justify-content: center
    align-items: flex-end

  @media (max-width: $mobileScreenMaxWidth)
    padding-bottom: 60px
    gap: 57px
    flex-direction: column
    align-items: center

  > .buttons-group
    display: flex
    position: absolute
    margin-top: 400px

    > *
      width: 130px
      height: 50px
      color: white
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer

      &.women
        background-color: $crimson_red
        +border-radius(999px 0 0 999px)
        border: 1px solid white

      &.men
        background-color: black
        +border-radius(0 999px 999px 0)
        border: 1px solid white
        margin-left: -1px

  .left
    display: flex
    flex-direction: column
    align-items: flex-end
    position: relative

    @media (min-width: $desktopScreenMinWidth)
      margin-right: 45px

    @media (max-width: $mobileScreenMaxWidth)
      width: 100%

    .round-image
      @media (min-width: $desktopScreenMinWidth)
        width: 495px
        height: 495px
        +border-radius(50%)
        +background-image-settings()
        cursor: pointer

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%
        height: 500px
        +background-image-settings(cover, no-repeat, top center)
        pointer-events: none
        display: flex
        justify-content: center
        align-items: flex-end
        padding-bottom: 50px

    .shop-now
      +border-radius(50%)
      border: 10px solid white
      background-color: $crimson_red
      position: absolute
      +user-select(none)
      cursor: pointer

      @media (min-width: $desktopScreenMinWidth)
        width: 180px
        height: 180px
        padding: 6px
        right: -65px
        bottom: -35px

      @media (max-width: $mobileScreenMaxWidth)
        width: 140px
        height: 140px
        padding: 4px
        bottom: -57px

      .line
        border: 2px solid white
        +border-radius(50%)
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        text-align: center

        > *
          font-size: 24px
          line-height: 29px

          @media (min-width: $desktopScreenMinWidth)
            color: white


  .description
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      max-width: 317px
      gap: 20px

    @media (max-width: $mobileScreenMaxWidth)
      max-width: 320px
      gap: 10px

    .name
      line-height: 120%
      text-align: center
      cursor: pointer

      @media (min-width: $desktopScreenMinWidth)
        font-size: 60px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 24px

      .text-red
        color: $crimson-red

    .info
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 20px
        line-height: 150%

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 18px
        line-height: 130%

    .cost
      line-height: 130%
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 30px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 20px

    .buttons-group
      display: flex
      margin-top: 50px
      gap: 20px
      justify-content: center

      > *
        width: 120px
        height: 120px
        +border-radius(999px)
        box-sizing: border-box
        position: relative
        display: flex
        align-items: center
        justify-content: center
        color: white
        font-size: 20px
        cursor: pointer

        &::after
          content: ""
          display: flex
          width: calc(100% - 10px)
          height: calc(100% - 10px)
          +border-radius(999px)
          border: 3px solid white
          box-sizing: border-box
          position: absolute
          left: 0
          top: 0
          margin: 5px

        &.women
          background-color: $crimson_red

        &.men
          background-color: black


  .right
    @media (min-width: $desktopScreenMinWidth)
      +background-image-settings()
      width: 369px
      height: 495px
      +border-radius(20px)
      transform: rotate(4deg)
      cursor: pointer

    @media (max-width: $mobileScreenMaxWidth)
      display: none
</style>
