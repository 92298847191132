<template>
  <div class="roska-catalog-filter-menu">
    <roska-filter-menu-item title="PRICE">
      <roska-catalog-filter-menu-price
          @updatePrice="(min, max) => $emit('updatePrice', min, max)"
      />
    </roska-filter-menu-item>

    <roska-filter-menu-item title="SIZE">
      <roska-catalog-filter-menu-size />
    </roska-filter-menu-item>
  </div>
</template>

<script>
import roskaFilterMenuItem from "@/components/UI-kit/filter/roska-filter-menu-item";
import roskaCatalogFilterMenuPrice from "@/components/templates/catalog/options/filterMenu/roska-catalog-filter-menu-price";
import roskaCatalogFilterMenuSize from "@/components/templates/catalog/options/filterMenu/roska-catalog-filter-menu-size";

export default {
  name: "roska-catalog-filter-menu",

  components: {
    roskaFilterMenuItem,
    roskaCatalogFilterMenuPrice,
    roskaCatalogFilterMenuSize,
  },

  methods: {
    setClose() {
      document.addEventListener('click', this.close)
      document.removeEventListener('click', this.setClose)
    },

    close(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit('close')
        document.removeEventListener('click', this.close)
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.setClose)
  },
}
</script>

<style scoped lang="sass">
.roska-catalog-filter-menu
  width: 289px
  background-color: white
  +border-radius(20px)
  position: absolute
  top: 0
  z-index: 99
  display: flex
  flex-direction: column
  gap: 10px
  cursor: default
  overflow: hidden

  @media (min-width: $desktopScreenMinWidth)
    left: 0

  @media (max-width: $mobileScreenMaxWidth)
    right: 0
</style>
