<template>
  <div class="roska-header">
    <roska-header-menu
        v-show="!isBurgerMenuHidden"
        :checkBeforeLinkClick="!isUserLoggedIn"
        @closeButtonClick="isBurgerMenuHidden = !isBurgerMenuHidden"
        @signInClick="handleAccountClick"
    />

    <div
        :class="{
          'gray': true,
          'back': true,
          'only-ds': true
        }"
    ></div>

    <div
        class="shadow back only-mb"
    ></div>

    <div
        class="black back only-mb"
        :style="{
          opacity: Math.min(scrollY / 100, 100)
        }"
    ></div>

    <nav class="only-ds">
      <router-link
          :to="{ path: '/catalog', query: { sex: 'man' }}"
          v-slot="{ navigate }"
          custom
      >
        <h5 @click="navigate">MAN</h5>
      </router-link>

      <router-link
          :to="{ path: '/catalog', query: { sex: 'women' }}"
          v-slot="{ navigate }"
          custom
      >
        <h5 @click="navigate">WOMEN</h5>
      </router-link>

      <router-link
          :to="{ path: '/collection' }"
          v-slot="{ navigate }"
          custom
      >
        <div class="with-arrow" @click="navigate">
          <h5>COLLECTION</h5>

          <div class="arrow"></div>
        </div>
      </router-link>

      <router-link
          :to="{ path: '/sale' }"
          v-slot="{ navigate }"
          custom
      >
        <h5 @click="navigate">SALE</h5>
      </router-link>
    </nav>

    <div
        class="main-page only-mb"
    >
      <div class="burger only-mb" @click="isBurgerMenuHidden = !isBurgerMenuHidden">
        <div
            :style="{
              backgroundColor: 'white'
            }"
            class="line-black"
        ></div>

        <div class="line-red"></div>

        <div
            :style="{
              backgroundColor: 'white'
            }"
            class="line-black"
        ></div>
      </div>
    </div>

    <roska-logo class="logo"/>

    <div class="tools">
      <roska-header-icon
          src="/assets/images/header/find.svg"
          link="/search"
          class="only-ds"
      />

      <roska-header-icon
          src="/assets/images/header/hearth.svg"
          link="/wishlist"
          :count="wishlistCount"
          class="only-ds"
      />

      <roska-header-icon
          class="only-ds"
          src="/assets/images/header/basket.svg"
          link="/basket"
          :count="bagCount"
      />

      <roska-header-icon
          class="only-mb"
          src="/assets/images/header/basket-white.svg"
          link="/basket"
          :count="bagCount"
      />

      <roska-header-icon
          src="/assets/images/header/profile.svg"
          link="/account"
          class="only-ds"
          :checkBeforeLinkClick="!isUserLoggedIn"
          @click="handleAccountClick"
      />
    </div>
  </div>
</template>

<script>
import roskaLogo from "@/components/UI-kit/roska-logo";
import roskaHeaderMenu from "@/components/UI-kit/header/roska-header-menu";
import roskaHeaderIcon from "@/components/UI-kit/header/roska-header-icon";

export default {
  name: "roska-header",

  components: {
    roskaLogo,
    roskaHeaderMenu,
    roskaHeaderIcon,
  },

  props: {
    wishlistCount: {
      type: Number,
      default: 0,
    },

    bagCount: {
      type: Number,
      default: 0,
    },

    isUserLoggedIn: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
      "openModal"
  ],

  data() {
    return {
      isBurgerMenuHidden: true,

      scrollY: 0,
    }
  },

  methods: {
    handleAccountClick() {
      if (this.isUserLoggedIn) {
        return
      }

      this.isBurgerMenuHidden = true;
      this.$emit('openModal')
    },
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY
    })
  }
}
</script>

<style scoped lang="sass">
.roska-header
  display: flex
  align-self: center
  width: 100%
  position: sticky
  top: 0
  z-index: 9999
  align-items: center

  @media (min-width: $desktopScreenMinWidth)
    padding: 40px calc((100% - 1216px) / 2) 20px

  @media (max-width: $mobileScreenMaxWidth)
    padding: 10px 20px 10px
    justify-content: space-between
    gap: 30px

  .back
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -2

    &.gray
      background-color: $gray

    &.shadow
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)

    &.black
      background-color: black
      opacity: 0

  nav
    display: flex
    gap: 30px
    align-items: center
    font-size: 20px
    line-height: 100%
    width: 100%
    flex: 1

    > *
      cursor: pointer

    .with-arrow
      display: flex
      align-items: center

      .arrow
        +background-image-settings()
        background-image: url("/public/assets/images/header/arrow-bottom.svg")
        width: 24px
        height: 24px

  .main-page
    width: 100%
    flex: 1

    .burger
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      gap: 6px
      cursor: pointer
      width: 32px
      height: 32px

      > *
        width: 24px
        height: 2px
        +border-radius(1px)

        &.line-black
          background-color: black

        &.line-red
          background-color: $crimson-red

  .tools
    flex: 1
    display: flex
    align-items: center
    width: 100%
    justify-content: flex-end

    @media (min-width: $desktopScreenMinWidth)
      gap: 30px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 20px
</style>
