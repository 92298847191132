<template>
  <div class="roska-product-about">
    <div class="select-part">
      <h3
          class="title"
          :class="{ 'active': selectedPart === 1 }"
          @click="selectPart(1)"
      >DESCRIPTION</h3>

      <h3
          class="title"
          :class="{ 'active': selectedPart === 2 }"
          @click="selectPart(2)"
      >DETAIL</h3>

      <h3
          class="title"
          :class="{ 'active': selectedPart === 3 }"
          @click="selectPart(3)"
      >SHIPPING</h3>
    </div>

    <div class="selected-part">
      <div
          v-show="selectedPart === 1"
          class="description"
      >
        <h5>{{ product.description }}</h5>
      </div>

      <div
          v-show="selectedPart === 2"
          class="detail"
      >
        <div
            class="option"
            v-for="detail in details"
            :key="detail.key"
        >
          <h5 class="title">{{ detail.title }}:</h5>

          <h5>{{ product.detail[detail.key] }}</h5>
        </div>
      </div>

      <div
          v-show="selectedPart === 3"
          class="shipping"
      >
        <!-- @TODO Определиться с разделом Shipping на странице товара -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-product-about",

  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      selectedPart: 1,

      details: [
        {
          title: "Fit",
          key: "fit",
        },

        {
          title: "Lining",
          key: "lining",
        },

        {
          title: "Length",
          key: "length",
        },

        {
          title: "Sleeve Length",
          key: "sleeve_length",
        },

        {
          title: "Collar",
          key: "collar",
        },
      ],
    }
  },

  methods: {
    selectPart(partNumber) {
      if (this.selectedPart !== partNumber) {
        this.selectedPart = partNumber
      }
    },
  },
}
</script>

<style scoped lang="sass">
.roska-product-about
  display: flex
  flex-direction: column

  @media (min-width: $desktopScreenMinWidth)
    gap: 30px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 20px

  .select-part
    display: flex
    flex-wrap: wrap
    gap: 5px 20px

    .title
      cursor: pointer
      color: $text-lightgray

      &.active
        color: black

  .selected-part
    .detail
      display: flex
      flex-direction: column
      gap: 6px

      .option
        display: flex
        gap: 6px
        font-size: 20px
        line-height: 140%

        .title
          color: $text-lightgray
</style>
