<template>
  <div class="roska-account-purchase">
    <div class="status-bar">
      <div
          class="part"
      >
        <div class="line">
          <h5>&nbsp;</h5>
        </div>

        <div class="line progress">
          <div
              class="status"
              :style="`width: 0`"
          ></div>
        </div>

        <div
            class="line active"
        >
          <h3 class="count">0</h3>
        </div>
      </div>

      <div
          v-for="(part, index) in statusBar"
          :key="part.title"
          class="part"
      >
        <div class="line">
          <h5>{{ part.title }}</h5>
        </div>

        <div class="line progress">
          <div
              class="status"
              :style="`width: ${ Math.min(100, 100 * (sumOfPurchases - (index ? statusBar[index - 1].count : 0)) / (part.count - (index ? statusBar[index - 1].count : 0))) }%`"
          ></div>
        </div>

        <div
            class="line"
            :class="{
                'active': sumOfPurchases >= part.count
            }"
        >
          <h3 class="count">€{{ part.count }}</h3>
        </div>
      </div>
    </div>

    <div class="description">
      <div class="option">
        <h5 class="title">TOTAL AMOUNT OF PURCHASES:</h5>

        <h5 class="value">€{{ sumOfPurchases.toFixed(2) }}</h5>
      </div>

      <div class="option">
        <h5 class="title">STATUS:</h5>

        <h5 class="value">{{ status }}</h5>
      </div>

      <div class="option">
        <h5 class="title">YOUR SALE:</h5>

        <h5 class="value">{{ sale }}%</h5>
      </div>

      <div v-if="isMotivationShown" class="option">
        <h5 class="title">UP TO {{ nextStatus }} STATUS:</h5>

        <h5 class="value">€{{ nextStatusValue.toFixed(2) }} </h5>
      </div>
    </div>

    <div class="card">
      <div class="image"></div>

      <div class="text">
        <h5 class="title">CARD HOLDER</h5>

        <div class="info">
          <h3 class="name" v-html="cardholderName" />

          <h5 class="number" v-html="cardNumber" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-account-purchase",

  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      statusBar: [
        {
          title: "BRONZE",
          count: 1000,
        },

        {
          title: "SILVER",
          count: 10000,
        },

        {
          title: "GOLD",
          count: 100000,
        }
      ],
    }
  },

  computed: {
    cardholderName() {
      if (Object.keys(this.userData).length === 0) {
        return ""
      }

      return [this.userData.first_name, this.userData.last_name].join(" ")
    },

    cardNumber() {
      if (Object.keys(this.userData).length === 0) {
        return ""
      }

      return this.userData['discount_card_number']
    },

    sumOfPurchases() {
      if (Object.keys(this.userData).length === 0) {
        return 0
      }

      return this.userData['total_purchases']
    },

    status() {
      if (this.sumOfPurchases < 1000) {
        return 'NONE'
      }

      if (this.sumOfPurchases < 10000) {
        return 'BRONZE'
      }

      if (this.sumOfPurchases < 100000) {
        return 'SILVER'
      }

      return 'GOLD'
    },

    sale() {
      if (this.sumOfPurchases < 1000) {
        return 0
      }

      if (this.sumOfPurchases < 10000) {
        return 3
      }

      if (this.sumOfPurchases < 100000) {
        return 5
      }

      return 7
    },

    isMotivationShown() {
      return this.sumOfPurchases < 100000
    },

    nextStatus() {
      if (this.sumOfPurchases < 1000) {
        return 'BRONZE'
      }

      if (this.sumOfPurchases < 10000) {
        return 'SILVER'
      }

      return 'GOLD'
    },

    nextStatusValue() {
      if (this.sumOfPurchases < 1000) {
        return 1000 - this.sumOfPurchases
      }

      if (this.sumOfPurchases < 10000) {
        return 10000 - this.sumOfPurchases
      }

      return 100000 - this.sumOfPurchases
    },
  },
}
</script>

<style scoped lang="sass">
.roska-account-purchase
  display: flex
  flex-direction: column
  gap: 30px

  @media (min-width: $desktopScreenMinWidth)
    max-width: 500px

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%
    padding: 0 20px
    align-items: center

  .status-bar
    display: flex
    width: 100%

    .part
      height: 100%
      +box-sizing(border-box)
      display: flex
      flex-direction: column
      align-items: flex-end
      justify-content: space-between
      gap: 10px

      @media (min-width: $desktopScreenMinWidth)
        width: calc(100 / 3) * 1%

      @media (max-width: $mobileScreenMaxWidth)
        width: calc(95 / 3) * 1%

      .line
        width: 0
        line-height: 150%
        display: flex
        align-items: center
        justify-content: center
        z-index: 9

        @media (min-width: $desktopScreenMinWidth)
          font-size: 17px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 14px

        &.progress
          width: 100%
          height: 11px
          overflow: hidden
          display: flex
          justify-content: flex-start
          background-color: $paginationGray

          .status
            height: 100%
            background-color: $crimson-red

        &.active
          .count
            color: $crimson-red

        .count
          color: $text-lightgray

      &:first-of-type
        width: 0

      &:nth-child(2)
        .line
          &.progress
            +border-radius(999px 0 0 999px)

      &:last-of-type
        .line
          &.progress
            +border-radius(0 999px 999px 0)

  .description
    display: flex
    flex-direction: column
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 10px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 6px

    .option
      display: flex
      gap: 4px
      align-items: baseline

      .title
        font-size: 15px
        line-height: 150%
        color: $text-gray

      .value
        line-height: 150%

        @media (min-width: $desktopScreenMinWidth)
          font-size: 17px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 15px

  .card
    overflow: hidden
    display: flex
    flex-direction: column
    background-color: black
    border: 5px solid black
    color: $text-card

    @media (min-width: $desktopScreenMinWidth)
      width: 495px
      +border-radius(16px)

    @media (max-width: $mobileScreenMaxWidth)
      max-width: 320px
      width: 100%
      +border-radius(10px)

    .image
      +background-image-settings()
      background-image: url("/src/assets/images/logo/logo.svg")
      width: 100%
      padding-top: calc(250 / 648) * 100%
      +opacity(50)

    .text
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 10px
        padding: 40px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 6px
        padding: 25px

      .title
        line-height: 100%

        @media (min-width: $desktopScreenMinWidth)
          font-size: 12px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 8px

      .info
        line-height: 100%
        display: flex
        justify-content: space-between

        @media (min-width: $desktopScreenMinWidth)
          font-size: 20px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 13px

        .name
          color: $crimson-red
</style>
