<template>
  <div
      class="roska-product-select-size"
      :class="{
        'warning': warning,
      }"
  >
    <roska-size-round
        v-for="size in Object.keys(sizes)"
        :key="`roska-product-select-size-${size}`"
        :active="size === selectedSize"
        :disabled="!sizes[size]"
        :clickable="true"
        :title="size"
        @click="selectSize(size)"
    />
  </div>
</template>

<script>
import roskaSizeRound from "@/components/UI-kit/size-round/roska-size-round";

export default {
  name: "roska-product-select-size",

  components: {
    roskaSizeRound,
  },

  props: {
    sizes: {
      type: Object,
      default: () => {
        return {}
      }
    },

    selectedSize: {
      type: String,
      default: null,
    },

    warning: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectSize(sizeTitle) {
      if (!this.sizes[sizeTitle]) {
        return
      }

      if (this.selectedSize === sizeTitle) {
        return
      }

      this.$emit('changeSize', sizeTitle)
    },
  },
}
</script>

<style scoped lang="sass">
.roska-product-select-size
  display: flex
  flex-wrap: wrap
  gap: 10px
  +user-select(none)
  +border-radius(999px)
  padding: 4px

  &.warning
    +border-radius(999px)
    background-color: rgba(255, 0, 0, 0.2)
</style>
