<template>
  <div class="roska-card-images-desktop">
    <div
        v-for="(image, index) in images"
        :key="index"
        class="image-wrapper"
    >
      <div
          class="image"
          :style="{ backgroundImage: `url('${ image }')` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-card-images-desktop",

  props: {
    images: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<style scoped lang="sass">
.roska-card-images-desktop
  width: 598px
  display: flex
  flex-wrap: wrap
  gap: 20px

  .image-wrapper
    width: 100%

    .image
      padding-top: 100%
      width: 100%
      +background-image-settings()

    &:nth-child(3n - 2)
      .image
        +border-radius(20px)

    &:nth-child(3n - 1)
      flex: 1

      .image
        +border-radius(50%)

    &:nth-child(3n)
      flex: 1

      .image
        +border-radius(12px)
</style>
