<template>
  <div class="roska-account-order-history">
    <div
        v-for="(order, index) in orders"
        :key="'roska-account-orders-' + index"
        class="order"
    >
      <hr>

      <div class="description" @click="redirectToOrderPage(order.id)">
        <div class="wrapper">
          <h5 class="id">ORDER {{ getOrderId(order.id) }}</h5>

          <h3 class="cost">€{{ order.value.toFixed(2) }}</h3>
        </div>

        <div class="wrapper">
          <h5>{{ order.products.reduce((a, b) => a + b.quantity, 0) }} ITEM{{ order.products.reduce((a, b) => a + b.quantity, 0) > 1 ? 'S' : '' }}</h5>

          <h5 v-html="getDateFromUTCString(order.created)" />

          <h5 v-html="'STATUS: ' + order.status.toUpperCase()" />
        </div>
      </div>

      <swiper
          class="swiper roskaAccountOrdersSwiper"
          :spaceBetween="10"
          :slides-per-view="getSlidersVolume"
          @touchStart="grabbing"
          @touchEnd="grabbing"
      >
        <swiper-slide
            v-for="item in order.products"
            :key="item.product_id"
            class="slide roskaAccountOrdersSlide"
        >
          <div
              class="image"
              :style="{ backgroundImage: getImageUrlFromId(item.product_id) }"
              @click="redirectToProductPage(item.product_id)"
          ></div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2';

export default {
  name: "roska-account-order-history",

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    userId: {
      type: String,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      productsList: [],

      swapperWidth: 0,
      swapperSlideWidth: 0,

      orders: [],
    }
  },

  computed: {
    getSlidersVolume() {
      if (this.swapperWidth) {
        return Math.floor((this.swapperWidth - 40 + 10) / 110)
      }

      if (this.swapperWidth >= 289 * 6 + 20 * 5) {
        return 6 + 0 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 5 + 20 * 4) {
        return 5 + 0 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 4 + 20 * 3) {
        return 4 + 0 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 3 + 20 * 2) {
        return 3 + 0 / this.swapperSlideWidth
      }

      if (this.swapperWidth >= 289 * 2 + 20) {
        return 2 + 0 / this.swapperSlideWidth
      }

      if (this.swapperSlideWidth) {
        return 1 + 0 / this.swapperSlideWidth
      }

      return 4
    },
  },

  methods: {
    redirectToProductPage(productId) {
      this.$router.push({ path: "/catalog/" + productId })
    },

    redirectToOrderPage(orderId) {
      this.$router.push({ path: "/account/orders/" + orderId })
    },

    getOrderId(id) {
      return id.substring(0, 4) + '...' + id.substring(id.length - 4)
    },

    getDateFromUTCString(date) {
      const dateObj = new Date(date)

      const day = dateObj.getUTCDate().toString().padStart(2, '0')
      const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0')
      const year = dateObj.getUTCFullYear()

      return [day, month, year].join('.')
    },

    getImageUrlFromId(id) {
      if (this.productsList.length === 0) {
        return 'none'
      }

      const productObj = this.productsList.find(product => product.id === id)

      if (!productObj) {
        return 'none'
      }

      return 'url(https://media.roskainc.com/media/' + productObj['image1'] + ')'
    },

    getSwapperWidth() {
      this.swapperWidth = document.querySelector('.roskaAccountOrdersSwiper').clientWidth
      this.swapperSlideWidth = document.querySelector('.roskaAccountOrdersSlide').clientWidth
    },

    grabbing(e) {
      e.$el[0].classList.toggle('active')
    },

    async getAllProducts() {
      this.axios.get("https://api.roskainc.com/api/store/v1/products/readAll")
          .then(response => {
            console.log('GET ALL PRODUCTS')

            this.productsList = response.data
          })
          .catch(error => {
            console.log("Getting All Products Error:", error)
          })
    },

    async getUserOrders() {
      await this.axios.get("https://api.roskainc.com/api/store/v1/users/orders", {
        params: {
          user_id: this.userId,
        },
      })
          .then(response => {
            console.log('GET USER ORDERS LIST', response.data)

            this.orders = response.data
          })
          .catch(error => {
            console.log("GET USER ORDERS LIST ERROR", error)
          })
    },
  },

  async mounted() {
    await this.getAllProducts()
    await this.getUserOrders()

    this.getSwapperWidth()

    window.addEventListener('resize', this.getSwapperWidth)

    document.onload = this.getSwapperWidth
    window.onload = this.getSwapperWidth

    setTimeout(this.getSwapperWidth, 100)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getSwapperWidth)
  },
}
</script>

<style scoped lang="sass">
.roska-account-order-history
  display: flex
  flex-direction: column
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    gap: 30px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 12px

  .order
    display: flex
    flex-direction: column
    gap: 10px
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      padding-top: 30px

    @media (max-width: $mobileScreenMaxWidth)
      padding-top: 12px

    &:first-child
      padding-top: unset

      hr
        display: none

    hr
      background-color: $lightgray
      height: 1px

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        margin: 0 20px

    .description
      display: flex
      border: 1px solid transparent
      cursor: pointer
      user-select: none

      &:hover
        border: 1px solid black
        +border-radius(8px)

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px
        align-items: center
        padding: 6px 12px

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column
        gap: 10px
        margin: 0 20px
        padding: 6px 12px

      .wrapper
        display: flex
        align-items: center
        font-size: 15px
        line-height: 150%

        @media (min-width: $desktopScreenMinWidth)
          gap: 20px

        @media (max-width: $mobileScreenMaxWidth)
          justify-content: space-between

        &:nth-child(2)
          color: $text-lightgray

        .id
          font-size: 20px
          line-height: 150%

        .cost
          font-size: 17px
          line-height: 150%

    .swiper
      width: 100%
      cursor: grab !important

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        padding: 0 20px

      &.active
        cursor: grabbing !important

      .slide
        .image
          +background-image-settings()
          +border-radius(10px)
          width: 100%
          padding-top: 100%
</style>
