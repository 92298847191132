<template>
  <router-link
      v-if="!checkBeforeLinkClick"
      :to="link"
      class="roska-header-icon"
      :style="{
        backgroundImage: `url('${ src }')`
      }"
  >
    <div
        v-if="count"
        class="count"
    >
      {{ count }}
    </div>
  </router-link>

  <div
      v-else
      class="roska-header-icon"
      :style="{
        backgroundImage: `url('${ src }')`
      }"
      @click="handleClick"
  >
    <div
        v-if="count"
        class="count"
    >
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  name: "roska-header-icon",

  props: {
    src: {
      type: String,
      default: null,
    },

    count: {
      type: Number,
      default: 0,
    },

    link: {
      type: String,
      default: '/',
    },

    checkBeforeLinkClick: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick(e) {
      if (!this.checkBeforeLinkClick) {
        return
      }

      this.$emit('click', e)
    },
  },
}
</script>

<style scoped lang="sass">
.roska-header-icon
  +background-image-settings()
  width: 32px
  height: 32px
  cursor: pointer
  position: relative

  .count
    display: flex
    width: 24px
    height: 24px
    background-color: $crimson_red
    position: absolute
    top: -13px
    right: -9px
    +border-radius(50%)
    font-family: Inter, "Calibri Light", sans-serif
    font-size: 14px
    line-height: 17px
    align-items: center
    justify-content: center
    color: white
</style>
