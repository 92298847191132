var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roska-customers-also-liked"},[_c('h1',{staticClass:"title"},[_vm._v("CUSTOMERS ALSO LIKED")]),_c('main',[_c('div',{staticClass:"button"},[_c('div',{staticClass:"arrow-left",on:{"click":function($event){return _vm.swiperEl.swiper.slidePrev()}}})]),_c('swiper',{staticClass:"swiper roskaCustomersAlsoLikedSwiper",attrs:{"slidesPerView":_vm.slidersVolume,"breakpoints":{
          1: {
            spaceBetween: 10,
          },
          1920: {
            spaceBetween: 20,
          },
        }},on:{"touchStart":_vm.grabbing,"touchEnd":_vm.grabbing}},_vm._l((_vm.productsList),function(product,index){return _c('swiper-slide',{key:`roska-customers-also-liked-${product.id}`,staticClass:"slide roskaCustomersAlsoLikedSlide"},[_c('roska-product-card',{attrs:{"square":true,"round":index % 4 === 1,"article":product.id,"src":`https://media.roskainc.com/media/${product.image1}`,"title":product.title,"cost":product.price,"old-cost":product.old_price ? product.old_price : null}})],1)}),1),_c('div',{staticClass:"button"},[_c('div',{staticClass:"arrow-right",on:{"click":function($event){return _vm.swiperEl.swiper.slideNext()}}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }