<template>
  <div class="roska-main">
    <roska-main-welcome :product="welcomeProduct" />

    <roska-main-man :product="manProduct" />

    <roska-main-woman :product="womanProduct" />

    <roska-main-popular :productsList="filteredProductsList" />

    <roska-main-promo15 />
  </div>
</template>

<script>
import roskaMainWelcome from "@/components/templates/main/roska-main-welcome";
import roskaMainMan from "@/components/templates/main/roska-main-man";
import roskaMainWoman from "@/components/templates/main/roska-main-woman";
import roskaMainPopular from "@/components/templates/main/roska-main-popular";
import roskaMainPromo15 from "@/components/templates/main/roska-main-promo15";

export default {
  name: "roska-main",

  components: {
    roskaMainWelcome,
    roskaMainMan,
    roskaMainWoman,
    roskaMainPopular,
    roskaMainPromo15,
  },

  props: {
    productsList: {
      type: Array,
      default() {
        return []
      }
    },
  },

  data() {
    return {
      welcomeProductTitle: "NATURAL LEATHER JACKET",
      manProductKey: "MAIN_PAGE_MAN",
      womanProductKey: "MAIN_PAGE_WOMEN",
    }
  },

  computed: {
    welcomeProduct() {
      return this.productsList.find(product => product.title === this.welcomeProductTitle) || null
    },

    manProduct() {
      return this.productsList.find(product => product['short_description'] === this.manProductKey) || null
    },

    womanProduct() {
      return this.productsList.find(product => product['short_description'] === this.womanProductKey) || null
    },

    filteredProductsList() {
      return this.productsList.filter(product => {
        return product.title !== this.welcomeProductTitle &&
            product['short_description'] !== this.manProductKey &&
            product['short_description'] !== this.womanProductKey
      })
    },
  },
}
</script>

<style scoped lang="sass">
.roska-main
  min-height: 100%
  height: 100%
  width: 100%

  @media (min-width: $desktopScreenMinWidth)

  @media (max-width: $mobileScreenMaxWidth)
    margin-top: -89px
</style>
