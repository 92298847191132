<template>
  <div class="roska-account">
    <div class="menu">
      <router-link
          :to="{ path: '/account/orders' }"
          :class="{
              'active': page === 'orders'
          }"
      ><h5>ORDER HISTORY</h5></router-link>

      <router-link
          :to="{ path: '/account/purchase' }"
          :class="{
              'active': page === 'purchase'
          }"
      ><h5>PURCHASE AMOUNT</h5></router-link>

      <router-link
          :to="{ path: '/account' }"
          :class="{
              'active': page === null
          }"
      ><h5>PROFILE</h5></router-link>
    </div>

    <router-view
        class="page"
        :userData="userData"
        :userId="userId"
        @signOut="$emit('signOut')"
        @updateUserData="newUserData => $emit('updateUserData', newUserData)"
    />
  </div>
</template>

<script>
export default {
  name: "roska-account",

  props: {
    userId: {
      type: String,
      default: null,
    },

    userData: {
      type: Object,
      default() {
        return {}
      },
    }
  },

  computed: {
    page() {
      return this.$route.path.split("/")[2] || null
    },
  },

  created() {
    if (this.userId === null) {
      this.$emit('openModal')
      this.$router.push({ name: 'main' })
    }
  }
}
</script>

<style scoped lang="sass">
.roska-account
  display: flex

  @media (min-width: $desktopScreenMinWidth)
    width: 1216px
    padding: 60px 0 130px
    gap: 60px
    align-items: flex-start

  @media (max-width: $mobileScreenMaxWidth)
    width: 100%
    padding: 40px 0 60px
    gap: 30px
    flex-direction: column

  .menu
    display: flex
    flex-direction: column
    padding: 20px
    font-size: 20px
    line-height: 110%
    +border-radius(10px)
    border: 2px solid $crimson_red
    background-color: white
    white-space: nowrap

    @media (min-width: $desktopScreenMinWidth)
      gap: 20px
      width: 289px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 10px
      align-items: center
      margin: 0 20px

    > *
      cursor: pointer

      &.active
        color: $crimson-red

  .page
    flex: 1
</style>
