<template>
  <div id="app">
    <template v-if="!loading">
      <roska-header
          :wishlistCount="wishlist.length"
          :bagCount="bag.length"
          :isUserLoggedIn="isUserLoggedIn"
          @openModal="isShownSignInModal = true"
      />

      <router-view
          class="roska-content"
          :wishlist="wishlist"
          :bag="bag"
          :productsList="productsList"
          :userId="userId"
          :userData="userData"
          @like="like"
          @addToBag="addToBag"
          @editBag="editBag"
          @clearBag="clearBag"
          @openModal="isShownSignInModal = true"
          @signOut="signOut"
          @updateUserData="updateUserData"
      />

      <roska-footer/>

      <roska-sign-in
          v-if="isShownSignInModal"
          @closeModal="isShownSignInModal = false"
          @openSignUpModal="isShownSignUpModal = true"
          @updateUserId="updateUserId"
          @saveSignIn="saveSignIn"
      />

      <roska-sign-up
          v-if="isShownSignUpModal"
          :mailAddress="mailAddress"
          @closeModal="isShownSignUpModal = false"
          @openSignInModal="isShownSignInModal = true"
          @updateUserId="updateUserId"
          @saveSignIn="saveSignIn"
      />
    </template>
  </div>
</template>

<script>
import roskaHeader from "@/components/UI-kit/header/roska-header";
import roskaFooter from "@/components/UI-kit/footer/roska-footer";

import roskaSignIn from "@/components/UI-kit/modal/signIn/roska-signIn";
import roskaSignUp from "@/components/UI-kit/modal/signUp/roska-signUp";

export default {
  name: 'App',

  components: {
    roskaHeader,
    roskaFooter,

    roskaSignIn,
    roskaSignUp,
  },

  data() {
    return {
      wishlist: [],

      bag: [],

      isShownSignInModal: false,
      isShownSignUpModal: false,

      userId: null,
      userData: {},

      loading: true,

      productsList: [],

      mailAddress: null,
    }
  },

  computed: {
    isUserLoggedIn() {
      return this.userId !== null
    },
  },

  methods: {
    getAllProducts() {
      this.axios.get("https://api.roskainc.com/api/store/v1/products/readAll")
          .then(response => {
            this.productsList = response.data

            console.log('PRODUCTS LIST', response.data)
          })
          .catch(error => {
            console.log('Getting All Products Error', error)
          })
    },

    async readLocalLogPas() {
      const LSEmail = localStorage.getItem('email') || null
      const LSPassword = localStorage.getItem('password') || null

      const SSEmail = sessionStorage.getItem('email') || null
      const SSPassword = sessionStorage.getItem('password') || null

      const email = LSEmail || SSEmail
      const password = LSPassword || SSPassword

      if (email === null || password === null) {
        return
      }

      await this.signIn(email, password)
    },

    async signIn(email, password) {
      await this.axios.post("https://api.roskainc.com/api/store/v1/users/login", {
        email: email.toString(),
        password: password.toString(),
      })
          .then(response => {
            this.userId = response.data.user_id

            console.log('SIGNIN', response.data.user_id)
          })
          .catch(error => {
            console.log('Log In Error', error)
          })
    },

    async getUserData(userId) {
      await this.axios.get("https://api.roskainc.com/api/store/v1/users/read", {
        params: {
          user_uuid: userId
        },
      })
          .then(response => {
            this.userData = response.data

            console.log('USER DATA', response.data)
          })
          .catch(error => {
            console.log("Getting User Data Error:", error)
          })
    },

    async getWishlist() {
      if (this.isUserLoggedIn) {
        await this.getGlobalWishlist(this.userId)
      } else {
        this.getLocalWishlist()
      }
    },

    async getGlobalWishlist(userId) {
      await this.axios.get("https://api.roskainc.com/api/store/v1/users/favourites", {
        params: {
          user_id: userId
        },
      })
          .then(response => {
            this.wishlist = response.data

            console.log('GLOBAL WISHLIST', response.data)
          })
          .catch(error => {
            console.log("Getting User Wishlist Error:", error)
          })
    },

    getLocalWishlist() {
      this.wishlist = JSON.parse(localStorage.getItem('wishlist')) || []

      console.log('LOCAL WISHLIST')
    },

    async getBag() {
      if (this.isUserLoggedIn) {
        await this.getGlobalBag(this.userId)
      } else {
        this.getLocalBag()
      }
    },

    async getGlobalBag(userId) {
      await this.axios.get("https://api.roskainc.com/api/store/v1/users/cart", {
        params: {
          user_id: userId
        },
      })
          .then(response => {
            this.bag = response.data

            console.log('GLOBAL BAG', response.data)
          })
          .catch(error => {
            console.log("Getting User Wishlist Error:", error)
          })
    },

    getLocalBag() {
      this.bag = JSON.parse(localStorage.getItem('bag')) || []

      console.log('LOCAL BAG')
    },

    signOut() {
      this.userId = null
      this.userData = {}
      this.wishlist = []
      this.bag = []

      localStorage.removeItem('email')
      localStorage.removeItem('password')

      sessionStorage.removeItem('email')
      sessionStorage.removeItem('password')

      this.$router.push({ name: 'main' })
    },

    async like(id) {
      if (this.isUserLoggedIn) {
        await this.globalLike(id)
      } else {
        this.localLike(id)
      }
    },

    async globalLike(id) {
      await this.axios.patch("https://api.roskainc.com/api/store/v1/users/favourites", {
        user_id: this.userId,
        product_id: id,
        flag: !this.wishlist.includes(id),
      })
          .then(response => {
            this.getWishlist()

            console.log('GLOBAL LIKE', response)
          })
          .catch(error => {
            console.log(`Like Of Product ${id} Error`, error)
          })
    },

    localLike(id) {
      const index = this.wishlist.indexOf(id)

      if (index > -1) {
        this.wishlist.splice(index, 1)
      } else {
        this.wishlist.push(id)
      }

      console.log('LOCAL LIKE')
    },

    async addToBag(id, stripeId, color, size, count, maxCount) {
      const bagProduct = this.bag.find(product => product.product_id === id && product.stripe_id === stripeId && product.color === color && product.size === size)

      if (bagProduct === undefined) {
        if (this.isUserLoggedIn) {
          await this.globalEditBag(id, stripeId, color, size, count)
        } else {
          this.bag.push({
            product_id: id,
            stripe_id: stripeId,
            color: color,
            quantity: count,
            size: size,
          })

          console.log('LOCAL ADD TO BAG')
        }
      } else {
        if (bagProduct.quantity === maxCount) {
          return
        }

        const newCount = Math.min(maxCount, bagProduct.quantity + count)

        if (this.isUserLoggedIn) {
          await this.globalEditBag(id, stripeId, color, size, newCount)
        } else {
          bagProduct.quantity = newCount

          console.log('LOCAL ADD TO BAG')
        }
      }
    },

    async globalEditBag(id, stripeId, color, size, count) {
      await this.axios.patch("https://api.roskainc.com/api/store/v1/users/cart", {
        user_id: this.userId,
        stripe_id: stripeId,
        product_id: id,
        color: color,
        quantity: count,
        size: size,
      })
          .then(response => {
            this.bag = response.data

            console.log('GLOBAL EDIT BAG', response.data)
          })
          .catch(error => {
            console.log(`Edit Bag Error`, error)
          })
    },

    async editBag(id, stripeId, color, size, count) {
      if (this.isUserLoggedIn) {
        await this.globalEditBag(id, stripeId, color, size, count)
      } else {
        if (count === 0) {
          const bagProductIndex = this.bag.findIndex(product => product.product_id === id && product.stripe_id === stripeId && product.color === color && product.size === size)

          this.bag.splice(bagProductIndex, 1)
        } else {
          const bagProduct = this.bag.find(product => product.product_id === id && product.stripe_id === stripeId && product.color === color && product.size === size)

          bagProduct.quantity = count
        }
      }
    },

    clearBag() {
      this.bag = []
    },

    async updateUserId(userId) {
      this.userId = userId

      await this.getWishlist()

      await this.getBag()
    },

    async updateUserData(userData) {
      await this.axios.patch("https://api.roskainc.com/api/store/v1/users/update", userData, {
        params: {
          user_uuid: this.userId,
        }
      })
          .then(async () => {
            console.log('GLOBAL UPDATE USER DATA')

            await this.getUserData(this.userId)
          })
          .catch(error => {
            console.log("Update User Data Error", error)
          })
    },

    saveSignIn(email, password, deep = false) {
      sessionStorage.setItem('email', email)
      sessionStorage.setItem('password', password)

      if (deep) {
        localStorage.setItem('email', email)
        localStorage.setItem('password', password)
      }
    },
  },

  watch: {
    $route() {
      let string = this.$route.meta.title

      const sex = this.$route.query.sex

      if (sex) {
        string += " - " + sex.charAt(0).toUpperCase() + sex.slice(1)
      }

      document.title = string
    },

    wishlist: {
      deep: true,
      handler() {
        if (this.isUserLoggedIn) {
          return
        }

        localStorage.setItem('wishlist', JSON.stringify(this.wishlist))
      },
    },

    bag: {
      deep: true,
      handler() {
        if (this.isUserLoggedIn) {
          return
        }

        localStorage.setItem('bag', JSON.stringify(this.bag))
      },
    },

    async userId() {
      this.userData = {}

      if (this.userId === null) {
        return
      }

      await this.getUserData(this.userId)
    }
  },

  async created() {
    this.getAllProducts()

    await this.readLocalLogPas()

    await this.getWishlist()

    await this.getBag()

    this.loading = false
  },
}
</script>

<style lang="sass">
#app
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  min-height: 100vh

  .roska-content
    flex: 1
</style>
