<template>
  <div class="roska-pagination" v-show="pageVolume > 1">
    <div
        class="arrow-left"
        @click="pageMinus"
    ></div>

    <div class="pages">
      <h5
          class="pageButton"
          v-for="num in pageElements"
          :key="num"
          :class="{
            'active': currentPage === num
          }"
          @click="() => { if (num) $emit('changePage', num) }"
      >{{ num || "..." }}</h5>
    </div>

    <div
        class="arrow-right"
        @click="pagePlus"
    ></div>
  </div>
</template>

<script>
export default {
  name: "roska-pagination",

  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    productsVolume: {
      type: Number,
      default: 0,
    },

    maxProductsOnPage: {
      type: Number,
      default: 8,
    }
  },

  computed: {
    pageVolume() {
      return Math.ceil(this.productsVolume / this.maxProductsOnPage)
    },

    pageElements() {
      if (this.pageVolume > 5) {
        if (this.pageVolume - this.currentPage > 3) {
          if (this.currentPage === 1) {
            return [this.currentPage, this.currentPage + 1, this.currentPage + 2, 0, this.pageVolume]
          } else {
            return [this.currentPage - 1, this.currentPage, this.currentPage + 1, 0, this.pageVolume]
          }
        } else {
          return [...Array(5).keys()].map(a => a + this.pageVolume - 4)
        }
      } else {
        return [...Array(this.pageVolume).keys()].map(a => a + 1)
      }
    }
  },

  methods: {
    pageMinus() {
      if (this.currentPage > 1) {
        this.$emit('changePage', this.currentPage - 1)
      }
    },

    pagePlus() {
      if (this.currentPage < this.pageVolume) {
        this.$emit('changePage', this.currentPage + 1)
      }
    }
  },

  watch: {
    productsVolume: function() {
      if (this.currentPage > this.pageVolume && this.pageVolume) {
        this.$emit('changePage', this.pageVolume)
      }
    },
  },
}
</script>

<style scoped lang="sass">
.roska-pagination
  display: flex
  gap: 20px
  align-items: center
  margin-top: 30px
  justify-content: center
  +user-select(none)

  .arrow-left
    +background-image-settings()
    background-image: url("/src/assets/images/catalog/pagination/arrow-left.svg")
    width: 16px
    height: 16px
    cursor: pointer

  .pages
    display: flex
    gap: 10px

    .pageButton
      color: $text-gray
      width: 44px
      height: 44px
      background-color: $paginationGray
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      +border-radius(100px)
      cursor: pointer

      *
        font-size: 20px
        line-height: 150%

      &.active
        background-color: $crimson_red
        color: white

  .arrow-right
    +background-image-settings()
    background-image: url("/src/assets/images/catalog/pagination/arrow-right.svg")
    width: 16px
    height: 16px
    cursor: pointer
</style>
